import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Modal, Row, Form, Spinner, Image } from 'react-bootstrap';
import Pagination from '../Common/Pagination';
import * as Icon from 'react-bootstrap-icons';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import Select from "react-select";
import edit from "../../Assets/edit.svg";
import deletes from "../../Assets/delete.svg";

export const ManageDepartCredit = () => {
    const dispatch = useDispatch()

    const commonData = useSelector(state=> state?.commonData?.commonData)

    const [showAdd, setShowAdd] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showDel, setShowDel] = useState(false);
    const [showFilterModel, setShowFilterModel] = useState(false)
    const [loader, setLoader] = useState(false);

    // created staging Branhch -----------
    // States for form Select option ------
    const [deptList, setDeptList] = useState();
    const [depCreditList, setDepCreditList] = useState([])
    const [filterOptionList, setFilterOptionList] = useState([])

    // States for Pagination ----------
    const [pageNo, setPageNo] = useState(1);
    const [docPerPage, setDocPerPage] = useState(10);
    const [noOfPages, setNoOfPages] = useState();

    // States of Add Role ----------------
    const [id, setId] = useState();
    const [departmentName, setDepartmentName] = useState("");
    const [creditPoint, setCreditPoint] = useState("")
    const [fromDate, setFromDate] = useState("")
    const [toDate, setToDate] = useState("")
    const [filterDepartment, setFilterDepartment] = useState("")
    const [filtercreditPoint, setFilterCreditPoint] = useState("")
    const [filterFromDate, setFilterFromDate] = useState("")
    const [filterToDate, setFilterToDate] = useState("")

    // States of Add User Error ----------------
    const [departmentErr, setDepartmentErr] = useState(false);
    const [creditPointErr, setCreditPointErr] = useState(false)
    const [fromDateErr, setFromDateErr] = useState(false)
    const [toDateErr, setToDateErr] = useState(false)

    // States of Delete user -----------------------
    const [delId, setDelId] = useState();

    useEffect(() => {
        getAllDeptCredit();
        getFilterDeptCredit()
    }, [pageNo, docPerPage])

    const getAllDeptCredit = async () => {
        await fetch(process.env.REACT_APP_BASE_URL + `/departmentCredit?pageNo=${pageNo}&docPerPage=${docPerPage}`, {
            method: 'GET'
        }).then((res) => res.json()).then((response) => {
            setDepCreditList(response?.data?.departmentCredits);
            setNoOfPages(response.data.noOfPages);
            setLoader(false);
        }).catch((err) => {
            console.log("Error while getting users", err);
            setLoader(false);
        })
    }

    const closeAddModal = () => {
        setDepartmentName("");
        setCreditPoint("")
        setFromDate("")
        setToDate("")

        setDepartmentErr(false);
        setCreditPointErr(false)
        setFromDateErr(false)
        setToDateErr(false)

        setShowAdd(false);
        setShowEdit(false);
    }

    const addValidate = () => {
        let isValid = true;
        if (departmentName?.length === 0) {
            isValid = false;
            setDepartmentErr(true);
        } else if (creditPoint?.length === 0) {
            isValid = false;
            setCreditPointErr(true);
        } else if (fromDate?.length === 0 ) {
            isValid = false;
            setFromDateErr(true);
        } else if (toDate?.length === 0) {
            isValid = false;
            setToDateErr(true);
        }

        return isValid;
    }

    const editValidate = () => {
        let isValid = true;
        if (departmentName?.length === 0) {
            isValid = false;
            setDepartmentErr(true);
        } else if (creditPoint?.length === 0) {
            isValid = false;
            setCreditPointErr(true);
        } else if (fromDate?.length === 0 ) {
            isValid = false;
            setFromDateErr(true);
        } else if (toDate?.length === 0) {
            isValid = false;
            setToDateErr(true);
        }

        return isValid;
    }

    const addDeprtCredit = async () => {
        setLoader(true);

        if (addValidate()) {
            await fetch(process.env.REACT_APP_BASE_URL + '/departmentCredit/create', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    creditPoint: creditPoint,
                    departmentId: departmentName.value,
                    departmentName: departmentName.label,
                    fromDate: fromDate, //YYYY-MM-DD
                    toDate: toDate, //YYYY-MM-DD
                })
            }).then((res) => res.json()).then((response) => {
                if (response.message === "Record Created Successfully...!") {
                    toast.success("Pont credited to department successfully");
                    setLoader(false);
                    closeAddModal();
                    getAllDeptCredit();
                } 
            }).catch((err) => {
                console.log("Err while adding user", err);
                setLoader(false);
            })
        }
        else {
            setLoader(false);
        }
    }

    /* ----- Edit Modal --------*/
    const setEditData = (itm) => { 
        setId(itm?._id);
        const selectedDepart = commonData?.departments.find((option) => option.label === itm?.departmentName)
        setDepartmentName(selectedDepart);
        setCreditPoint(itm?.creditPoint)
        setFromDate(itm?.fromDate?.split('T')[0]); // Format "yyyy-mm-dd"
        setToDate(itm?.toDate?.split('T')[0]);
    }

    const editDeptCredit = async () => {
        setLoader(true);
        if (editValidate()) {
            await fetch(process.env.REACT_APP_BASE_URL + '/departmentCredit/update', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    id: id,
                    creditPoint: creditPoint,
                    departmentId: departmentName.value,
                    departmentName: departmentName.label,
                    fromDate: fromDate, //YYYY-MM-DD
                    toDate: toDate //YYYY-MM-DD
                })
            }).then((res) => res.json()).then((response) => {
                if (response.message === "Record Updated Successfully...!") {
                    toast.success("Department credit updated successfully");
                    setLoader(false);
                    setShowEdit(false);
                    closeAddModal();
                    getAllDeptCredit()
                }
            }).catch((err) => {
                console.log("Err while updating user", err);
                setLoader(false);
            })
        }
        else {
            setLoader(false);
        }
    }

    const deleteDepartcredit = async () => {
        setLoader(true);
        await fetch(process.env.REACT_APP_BASE_URL + `/departmentCredit/delete/${delId}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                id: delId
            })
        }).then((res) => res.json()).then((response) => {
            if (response.message === "Record Delete Successfully...!") {
                toast.success("Department credit deleted successfully !");
                setShowDel(false);
                getAllDeptCredit();
            }
            setLoader(false);
        }).catch((err) => {
            console.log("Err while adding user", err);
            setLoader(false);
        })
    }

    const getFilterDeptCredit = async() =>{
        await fetch(process.env.REACT_APP_BASE_URL + `/departmentCredit/filter?pageNo=${pageNo}&docPerPage=${docPerPage}`,{
            method:'POST',
            headers:{'Content-Type':'application/json'},
            body:JSON.stringify({
                departmentName : filterDepartment.value, 
                creditPoint : filtercreditPoint.value, 
                fromDate : filterFromDate,
                toDate : filterToDate
            })
        }).then((res) => res.json())
        .then((response) => {
            setDepCreditList(response.data.users)
            setShowFilterModel(false)
        })
        .catch((err)=>{
            console.log("err",err);
        })
    }

    useEffect(()=>{
        filterOptions()
    },[])

    const filterOptions = async() =>{
        await fetch(process.env.REACT_APP_BASE_URL + '/departmentCredit/filterOptions',{
            method:'GET',
            headers:{'Content-Type':'application/json'}
        }).then((res)=>res.json())
        .then((response)=>{
            setFilterOptionList(response.data)
        })
        .catch((err)=>{
            console.log("err",err);
        })
    }

    const clearFilter = () =>{
        setFilterDepartment("")
        setFilterCreditPoint("")
        setFilterFromDate("")
        setFilterToDate("")
    }

    // function for pagination
    const handlePageChange = (pageNumber) => {
        setPageNo(pageNumber);
    };

    const handleDocsPerPage = (docsPageProp) => {
        setPageNo(1)
        setDocPerPage(docsPageProp);
    }

    const handleCloseFilter = () =>{
        setShowFilterModel(false)
    }

    return (
        <div className='ManageUsers outletPadding'>
            <ToastContainer />
            <Container>
                <Row>
                    <Row className='justify-content-end align-items-center mb-2'>
                        {/* <Col md={3} className='d-flex mb-4 mb-md-0'>
                            <Button className='primaryBtn' onClick={()=>setShowFilterModel(true)}><Icon.Funnel className='me-2'/>Filter</Button>
                        </Col> */}
                        <Col md={3} className='d-flex justify-content-end'>
                            <Button className='primaryBtn' onClick={() => setShowAdd(true)}><Icon.CreditCard className='me-2'/>Assign Credit</Button>
                        </Col>
                    </Row>
                    <div className="outer-wrapper mx-auto">
                        {
                            loader ? <center><Spinner variant='primary' className='mt-3' /></center> :
                                <div className="table-wrapper" data-aos="fade-up">
                                    <table>
                                        <thead>
                                            <th>Sr No</th>
                                            <th>Department Name</th>
                                            <th>Credit Point</th>
                                            <th>Start Date</th>
                                            <th>End Date</th>
                                            <th>Actions</th>
                                        </thead>
                                        {
                                            depCreditList !== null && depCreditList !== undefined && depCreditList.length > 0 ? depCreditList.map((itm, index) => {
                                                const { departmentName, creditPoint, fromDate, toDate } = itm
                                                return (
                                                    <tr key={Math.random() * 999999999}>
                                                        <td>{pageNo !== 1 ? (<> {index + 1 + docPerPage * (pageNo - 1)}</>) : <>{index + 1}</>}</td>
                                                        <td>{departmentName}</td>
                                                        <td>{creditPoint ?creditPoint : "0"}</td>
                                                        <td>{new Date(fromDate).toLocaleDateString('en-GB')}</td>
                                                        <td>{new Date(toDate).toLocaleDateString('en-GB')}</td>
                                                        <td>
                                                            {/* <img src={edit} className='me-3 icon' style={{ cursor:'pointer' }}
                                                                onClick={() => { setShowEdit(true); setEditData(itm) }}/> */}
                                                            <img src={deletes} className='me-2 icon' style={{ cursor:'pointer' }}
                                                                onClick={() => { setShowDel(true); setDelId(itm?._id) }}/>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                                : 'No data Found'
                                        }
                                    </table>
                                </div>
                        }
                    </div>
                    <Pagination currentPage={pageNo} totalPages={noOfPages}
                        onPageChange={handlePageChange} onDocsPerPage={handleDocsPerPage} docPerPage={docPerPage} />

                </Row>

    {/* ---------------- Add User Modal ----------------------------------------------------------- */}
        <Modal size='md' show={showAdd} backdrop='static' keyboard={false} centered onHide={closeAddModal}>
            <Modal.Header closeButton>
                <Modal.Title> <h4 className='mb-0'><Icon.CreditCard size={20} className='me-2' />Assign Credit</h4> </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={6}>
                        <Form.Group className='mb-3'>
                        <Form.Label>Department Name<span className='errMsg'>*</span></Form.Label>
                        <Select
                            value={departmentName}
                            options={commonData?.departments}
                            onChange={(option)=>{
                                setDepartmentErr(false)
                                setDepartmentName(option)
                            }}
                        />
                        {departmentErr ? <p className='errMsg'>Please enter valid department name</p> : ''}
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className='mb-3'>
                        <Form.Label>Credit Point<span className='errMsg'>*</span></Form.Label>
                        <Form.Control
                            placeholder='Enter credit point'
                            name='creditPoint'
                            value={creditPoint}
                            onChange={(e) => {
                                if(/^[0-9.]+$/.test(e.target.value) && e.target.value !== '0') {
                                    setCreditPoint(e.target.value);
                                } else if (e.target.value.length === 0) {
                                    setCreditPoint(e.target.value);
                                }
                                e.target.value.length > 0 ?
                                setCreditPointErr(false) :
                                setCreditPointErr(true);
                            }}/>
                        {creditPointErr ? <p className='errMsg'>Please enter valid credit point</p> : ''}
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col md={6}>
                        <Form.Group className='mb-3'>
                        <Form.Label>Start Date<span className='errMsg'>*</span></Form.Label>
                        <Form.Control
                            type='date'
                            placeholder='Enter from date'
                            name='fromDate'
                            value={fromDate}
                            max={toDate}
                            // min={new Date().toISOString().split("T")[0]}
                            onKeyDown={(event) => event.preventDefault()}
                            onChange={(e) => {
                                setFromDate(e.target.value);
                                e.target.value.length > 0 ?
                                setFromDateErr(false) :
                                setFromDateErr(true);
                            }} />
                            {fromDateErr ? <p className='errMsg'>Please enter from date</p> : ''}
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className='mb-3'>
                        <Form.Label>End Date<span className='errMsg'>*</span></Form.Label>
                        <Form.Control
                            type='date'
                            placeholder='Enter to date'
                            name='toDate'
                            value={toDate}
                            min={fromDate}
                            onChange={(e) => {
                                setToDate(e.target.value);
                                e.target.value.length > 0 ?
                                setToDateErr(false) :
                                setToDateErr(true);}} />
                        {toDateErr ? <p className='errMsg'>Please enter to date</p> : ''}
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>

            <Modal.Footer>
                <Button className='primaryBtn' onClick={addDeprtCredit} disabled={loader}>
                {loader ? <Spinner size='sm' /> : <Icon.CreditCard className='me-2' />}Assign credit</Button>
            </Modal.Footer>
        </Modal>

    {/* ---------------- Edit User Modal ----------------------------------------------------------- */}
        {/* <Modal size='md' show={showEdit} backdrop='static' keyboard={false} centered onHide={closeAddModal}>
            <Modal.Header closeButton>
                <Modal.Title> <h4 className='mb-0'><Icon.PencilSquare size={20} className='me-2' />Edit Department Credit</h4> </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                <Col md={6}>
                        <Form.Group className='mb-3'>
                        <Form.Label>Department Name<span className='errMsg'>*</span></Form.Label>
                        <Select
                            value={departmentName}
                            options={commonData?.departments}
                            onChange={(option)=>{
                                setDepartmentErr(false)
                                setDepartmentName(option)
                            }}
                        />
                        {departmentErr ? <p className='errMsg'>Please enter valid department name</p> : ''}
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className='mb-3'>
                        <Form.Label>Credit Point<span className='errMsg'>*</span></Form.Label>
                        <Form.Control
                            placeholder='Enter credit point'
                            name='creditPoint'
                            value={creditPoint}
                            onChange={(e) => {
                                if (!e.target.value.match(/[A-Za-z+@#$&%!~=*./\s/g/\-/?<>,"/]/)) {
                                    setCreditPoint(e.target.value);
                                } else if (e.target.value.length === 0) {
                                    setCreditPoint(e.target.value);
                                }
                                e.target.value.length > 0 ?
                                setCreditPointErr(false) :
                                setCreditPointErr(true);}} />
                            {creditPointErr ? <p className='errMsg'>Please enter valid credit number</p> : ''}
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col md={6}>
                        <Form.Group className='mb-3'>
                        <Form.Label>From Date<span className='errMsg'>*</span></Form.Label>
                        <Form.Control
                            type='date'
                            placeholder='Enter from date'
                            name='fromDate'
                            value={fromDate}
                            // min={new Date().toISOString().split("T")[0]}
                            max={toDate}
                            onChange={(e) => {
                                setFromDate(e.target.value);
                                e.target.value.length > 0 ?
                                setFromDateErr(false) :
                                setFromDateErr(true);}} />
                            {fromDateErr ? <p className='errMsg'>Please enter from date</p> : ''}
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className='mb-3'>
                        <Form.Label>To Date<span className='errMsg'>*</span></Form.Label>
                        <Form.Control
                            type='date'
                            placeholder='Enter To date'
                            name='toDate'
                            value={toDate}
                            min={fromDate}
                            onChange={(e) => {
                                setToDate(e.target.value);
                                e.target.value.length > 0 ?
                                setToDateErr(false) :
                                setToDateErr(true);
                            }} />
                            {toDateErr ? <p className='errMsg'>Please enter to date</p> : ''}
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>

            <Modal.Footer>
                <Button className='primaryBtn' onClick={editDeptCredit} disabled={loader}>
                {loader ? <Spinner size='sm' /> : <Icon.PencilSquare className='me-2' />}Edit Department credit</Button>
            </Modal.Footer>
        </Modal> */}

    {/* ---------------- Delete User Modal ----------------------------------------------------------- */}
        <Modal size='md' show={showDel} backdrop='static' keyboard={false} centered onHide={() => setShowDel(false)}>
            <Modal.Header closeButton>
                <Modal.Title> <h4 className='mb-0'><Icon.Trash size={20} className='me-2' />Delete User</h4> </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4 className='text-center mb-0'>Are you sure to delete ?</h4>
            </Modal.Body>

            <Modal.Footer>
                <Button className='primaryBtn' onClick={deleteDepartcredit} disabled={loader}>
                {loader ? <Spinner size='sm' /> : <Icon.Trash className='me-1' />}Delete</Button>
            </Modal.Footer>
        </Modal>

    {/* ---------------- Filter Model ---------------------------------------------------------------- */}
        {/* <Modal size='md' show={showFilterModel} backdrop='static' keyboard={false} centered onHide={handleCloseFilter}>
            <Modal.Header closeButton>
                <Modal.Title> <h4 className='mb-0'><Icon.Funnel size={20} className='me-2' />Filter</h4> </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={6}>
                        <Form.Group className='mb-3'>
                        <Form.Label>Department</Form.Label>
                        <Select
                            isMulti={false}
                            isSearchable={true}
                            name='departmentName'
                            value={filterDepartment}
                            options={filterOptionList.departmentNameOption}
                            onChange={(option) => {
                                setFilterDepartment(option);
                            }}/>
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className='mb-3'>
                        <Form.Label>Credit Point</Form.Label>
                        <Select
                            isMulti={false}
                            isSearchable={true}
                            name='creditPoint'
                            value={filtercreditPoint}
                            options={filterOptionList?.creditPointOptions}
                            onChange={(option) => {
                                setFilterCreditPoint(option);
                            }}/>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col md={6}>
                        <Form.Group className='mb-3'>
                        <Form.Label>From Date</Form.Label>
                        <Form.Control
                            type='date'
                            placeholder='Enter from date'
                            name='fromDate'
                            value={filterFromDate}
                            onKeyDown={(event) => event.preventDefault()}
                            onChange={(e) => {
                                setFilterFromDate(e.target.value);
                            }}/>
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className='mb-3'>
                        <Form.Label>To Date</Form.Label>
                        <Form.Control
                            type='date'
                            placeholder='Enter to date'
                            name='toDate'
                            value={filterToDate}
                            min={filterFromDate}
                            onChange={(e) => {
                                setFilterToDate(e.target.value);
                            }}/>
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>

            <Modal.Footer>
                <Button className='primaryBtn' onClick={clearFilter} disabled={loader}><Icon.XCircle className='me-2'/>Reset</Button>
                <Button className='primaryBtn' onClick={getFilterDeptCredit} disabled={loader}>
                {loader ? <Spinner size='sm' /> : <Icon.Funnel className='me-2' />}Submit</Button>
            </Modal.Footer>
        </Modal> */}

    </Container>
    </div>
    )
}
