import React, { useContext, useState } from 'react'
import { Button, Col, Modal, Row, Form, Spinner, Image } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { ToastContainer, toast } from 'react-toastify';
import Select from "react-select";
import { useSelector } from 'react-redux';
import ReactS3 from '../../../Utils/ReactS3';
import { S3Context } from '../../Common/S3Provider';

const AddUserModel = ({show, close, getAllUsers, deptName, deptId}) => {
    const {s3Data} = useContext(S3Context);
    const [loader, setLoader] = useState(false);

    const [id, setId] = useState();
    const [profileImg, setProfileImg] = useState('')
    const [name, setName] = useState();
    const [mobile, setMobile] = useState();
    const [whatsaapNo, setWhatsaapNo] = useState();
    const [pass, setPass] = useState();
    const [email, setEmail] = useState();
    const [dept, setDept] = useState('');
    const [desg, setDesg] = useState();
    const [role, setRole] = useState();
    const [category, setCategory] = useState()

    const [profileImgErr, setProfileImgErr] = useState(false)
    const [nameErr, setNameErr] = useState(false);
    const [mobileErr, setMobileErr] = useState(false);
    const [whatsappErr, setWhatsappErr] = useState(false)
    const [passErr, setPassErr] = useState(false);
    const [passLengthErr, setpassLengthErr] = useState(false);
    const [emailErr, setEmailErr] = useState(false);
    const [deptErr, setDeptErr] = useState(false);
    const [desgErr, setDesgErr] = useState(false);
    const [roleErr, setRoleErr] = useState(false);

    const commonData = useSelector(state=> state?.commonData?.commonData)

    const closeAddModal=()=>{
        setProfileImg('')
        setName('');
        setMobile('');
        setWhatsaapNo('')
        setPass('');
        setEmail('');
        setDept('');
        setDesg('');
        setRole('');
        setCategory('')

        setProfileImgErr(false)
        setNameErr(false);
        setMobileErr(false);
        setWhatsappErr(false)
        setPassErr(false);
        setpassLengthErr(false)
        setEmailErr(false);
        setDeptErr(false);
        setDesgErr(false);
        setRoleErr(false);

        close()
    }

    const addValidate=()=>{
        let isValid = true;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if(profileImg?.length === 0){
            isValid  = false;
            setProfileImgErr(true)
        }else if(!name){
            isValid = false;
            setNameErr(true); 
        }
        else if(!mobile || mobile?.length<10){
            isValid = false;
            setMobileErr(true);
        }
        else if(!whatsaapNo || whatsaapNo?.length<10){
            isValid = false;
            setWhatsappErr(true);
        }
        else if(!pass){
            isValid = false;
            setPassErr(true);
        }
        else if(pass?.length < 5){
            isValid = false;
            setpassLengthErr(true);
        }
        else if(!email || !emailRegex.test(email)){
            isValid = false;
            setEmailErr(true);
        }
        else if(!dept){
            isValid = false;
            setDeptErr(true);
        }
        else if(!desg){
            isValid = false;
            setDesgErr(true);
        }
        else if(!role){
            isValid = false;
            setRoleErr(true);
        }
        return isValid;
    }

    const addUser=async()=>{    
        if(addValidate()){
            setLoader(true);
        
            await fetch(process.env.REACT_APP_BASE_URL + '/user/register',{
                method:'POST',
                headers:{'Content-Type':'application/json'},
                body:JSON.stringify({
                    profileImg:profileImg,
                    name:name,
                    contactNo: mobile,
                    email: email,
                    whatsappNo:whatsaapNo,
                    userTypeKey: role?.value,
                    categoryId: category?.value,
                    departmentId: dept?.value,
                    designation: desg,
                    password: pass

                })
            }).then((res)=>res.json()).then((response)=>{
                if(response.message === "An account with this mobile already exists."){
                    toast.error("User already exist with this mobile number");
                    setLoader(false);
                }
                else if(response.message === "user created successfully"){
                    toast.success("User added successfully");
                    setLoader(false);
                    closeAddModal();
                    getAllUsers();
                }
            }).catch((err)=>{
                console.log("Err while adding user", err);
                setLoader(false);
            }) 
        }
    }

    const handleProfileImg = async(e)=>{ 
        let result = await ReactS3(e.target.files[0], s3Data,"userImageDocs");
        setProfileImg(result);
    }
 
  return (
    <div>
        <Modal size='lg' show={show} backdrop='static' keyboard={false} centered onHide={closeAddModal}>
        <Modal.Header closeButton>
            <Modal.Title> <h4 className='mb-0'><Icon.PersonAdd size={20} className='me-2'/>Add User</h4> </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row className='justify-content-center'>
                <Col md={5}>
                    <Form.Group className='mb-3'>
                        {  profileImg ? 
                        <>
                            <Form.Label>Uploaded Image<span className='errMsg'>*</span></Form.Label>
                            <div className='uploadFile'>
                                <img src={profileImg} alt="profile Image"/>
                                <h6>{String(profileImg)?.split('/')[String(profileImg)?.split('/').length - 2]}</h6>
                                    <Icon.XLg className='me-2' size={20} onClick={()=>setProfileImg('')}/>
                            </div>
                        </> : 
                        <>
                            <Form.Label>Upload Profile Image<span className='errMsg'>*</span></Form.Label>
                            <Form.Control type='file'onChange={(e)=> { handleProfileImg(e); setProfileImgErr(false); }} />
                        </>
                        }
                    {profileImgErr ? <p className='errMsg'>Please select profile image</p> : ''}
                    </Form.Group> 
                </Col>
            </Row>

            <Row>
                <Col md={4}>
                    <Form.Group className='mb-3'>
                    <Form.Label>Name<span className='errMsg'>*</span></Form.Label>
                    <Form.Control placeholder='Enter name' value={name} 
                                onChange={(e)=> {if (!e.target.value.match(/[0-9+@#$&%!~=^_:(){}\[\]|/*./\//\-/?<>,;`'""/]/) &&
                                                e.target.value.trim() ) {
                                                    setName(e.target.value);
                                                } else if (e.target.value.length === 0) {
                                                    setName(e.target.value);
                                                }
                             e.target.value.length > 0 ? setNameErr(false) : setNameErr(true); }} />
                            {nameErr ? <p className='errMsg'>Please enter valid name</p> : ''}
                    </Form.Group> 
                </Col>

                <Col md={4}>
                    <Form.Group className='mb-3'>
                    <Form.Label>Mobile<span className='errMsg'>*</span></Form.Label>
                    <Form.Control placeholder='Enter mobile number' name='mobile' value={mobile}  
                                onChange={(e)=> {if (!e.target.value.match(/[A-Za-z+@#$&%!~=*./\s/g/\-/?<>,"/]/) && 
                                                e.target.value.length <= 10) {
                                                    setMobile(e.target.value);
                                                } else if (e.target.value.length === 0) {
                                                    setMobile(e.target.value);
                                                }
                                e.target.value.length > 0 ? setMobileErr(false) : setMobileErr(true)}}/>
                            {mobileErr ? <p className='errMsg'>Please enter valid mobile number</p> : ''}
                    </Form.Group>
                </Col>

                <Col md={4}>
                    <Form.Group className='mb-3'>
                    <Form.Label>Whatsapp number<span className='errMsg'>*</span></Form.Label>
                    <Form.Control placeholder='Enter whatsapp number' name='whatsaapNo' value={whatsaapNo}  
                                onChange={(e)=> {if (!e.target.value.match(/[A-Za-z+@#$&%!~=*./\s/g/\-/?<>,"/]/) && 
                                                    e.target.value.length <= 10) {
                                                        setWhatsaapNo(e.target.value);
                                                } else if (e.target.value.length === 0) {
                                                        setWhatsaapNo(e.target.value);
                                                }
                                e.target.value.length > 0 ? setWhatsappErr(false) : setWhatsappErr(true)}}/>
                            {whatsappErr ? <p className='errMsg'>Please enter valid whatsaap number</p> : ''}
                    </Form.Group>
                </Col>
            </Row>
                    
            <Row>
                <Col md={4}>
                    <Form.Group className='mb-3'>
                    <Form.Label>Password<span className='errMsg'>*</span></Form.Label>
                    <Form.Control placeholder='Enter password' value={pass} 
                                onChange={(e)=>{ setPass(e.target.value);
                                                if(e.target.value.length){ setPassErr(false); setpassLengthErr(false) }
                                                else{  setPassErr(true) }}}/>
                            {passErr ? <p className='errMsg'>Please enter valid password</p> : ''}
                            {passLengthErr ? <p className='errMsg'>Password cannot be less than 5 Digits </p> : ''}
                            </Form.Group>
                </Col>

                <Col md={4}>
                    <Form.Group className='mb-3'>
                    <Form.Label>Email<span className='errMsg'>*</span></Form.Label>
                    <Form.Control placeholder='Enter email' value={email} 
                                onChange={(e)=>{ setEmail(e.target.value);
                                                e.target.value?.length > 0 ? setEmailErr(false) : setEmailErr(true) }}/>
                            {emailErr ? <p className='errMsg'>Please enter valid email id</p> : ''}
                    </Form.Group>
                </Col>

                <Col md={4}>
                    <Form.Group className='mb-3'>
                    <Form.Label>Department<span className='errMsg'>*</span></Form.Label>
                    {/* { deptName ? <Form.Control disabled value={deptName}/> : } */}
                            <Select
                                isMulti={false}
                                isSearchable={true}
                                name='departmentName'
                                value={dept}
                                options={commonData?.departments}
                                onChange={(option) => {
                                    setDeptErr(false)
                                    setDept(option);
                                }}/>    
                      
                    {deptErr ? <p className='errMsg'>Please select any department</p> :''}
                        
                    </Form.Group> 
                </Col>
            </Row>

            <Row>
                <Col md={4}>
                    <Form.Group className='mb-3'>
                    <Form.Label>Designation<span className='errMsg'>*</span></Form.Label>
                    <Form.Control placeholder='Enter Designation' name='designation' value={desg} 
                                onChange={(e)=>{ 
                                    setDesg(e.target.value);
                                    e.target.value.length > 0 ?  setDesgErr(false) : setDesgErr(true) }}/>
                            { desgErr ? <p className='errMsg'>Please select any designation</p> : ''}
                    </Form.Group> 
                </Col>

                <Col md={4}>
                    <Form.Group className='mb-3'>
                    <Form.Label>Role<span className='errMsg'>*</span></Form.Label>
                    <Select
                            isMulti={false}
                            isSearchable={true}
                            value={role}
                            name='userTypeKey'
                            options={commonData?.userTypes}
                            onChange={(option) => {
                                setRoleErr(false)
                                setRole(option);
                            }}/>
                            { roleErr ? <p className='errMsg'>Please select any role</p> : ''}
                    </Form.Group> 
                </Col>

                <Col md={4}>
                    <Form.Group className='mb-3'>
                    <Form.Label>Category</Form.Label>
                        <Select
                            isMulti={false}
                            isSearchable={true}
                            name='categoryName'
                            value={category}
                            options={commonData?.categories}
                            onChange={(option) => {
                                setCategory(option);
                            }}/>
                    </Form.Group> 
                </Col>
            </Row>     
        </Modal.Body>

        <Modal.Footer>
            <Button className='primaryBtn' onClick={addUser} disabled={loader}> 
            { loader ? <Spinner size='sm'/> : <Icon.PersonAdd className='me-2'/> }Add User</Button>
        </Modal.Footer>
        </Modal>
    </div>
  )
}

export default AddUserModel
