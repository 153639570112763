import {React, useEffect } from 'react';
import ContactList from './ContactList';
import Conversation from './Conversation';
import laptop from '../../Assets/laptop.png';
import AccountProvider from './AccountProvider';

const MessengerLayout = () => {

  
  return (
    <>
      <div className=' d-flex justify-content-center align-items-center text-center d-block d-md-none' style={{height:'100vh'}}>
        
        <div>
          
          <h1 className='mb-0'>Please login in with desktop/Laptop to use this feature</h1>
        <img src={laptop}
          alt="laptop" width={400} height={300}/>
        </div>

      </div>

    <div className='MessengerLayout d-none d-md-block'>

      <div className='left'>
          <ContactList/>
      </div>

      <div className='right'>
        <Conversation/>
      </div>

    </div>
</>
  )
}

export default MessengerLayout;