import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import Lottie from 'react-lottie';
import RightOk from '../../Assets/Right-OK.json';

const ThankYou = () => {

    const ThankYou={
        loop: true,
        autoplay: true,
        animationData: RightOk,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      }

  return (
    <div className='ThankYou'>
        <Container className='h-100'>
            <Row className='h-100 justify-content-center align-items-center'>
                <div className='d-flex align-items-center flex-column'>
                    <Col xs={10} md={3}>
                      <Lottie options={ThankYou}/>
                    </Col>
                 <h2 className='text-center'>Your onboarding reponse has been submitted</h2>
                    <p className='text-center'>Please wait for admin reply, we will get back to you. Estimated waiting time is 24-48 Hrs</p>
            </div> 
            </Row>
        </Container>
    </div>
  )
}

export default ThankYou