import React, { useEffect, useState,useRef } from 'react';
import { Table, Row, Col, Button, Modal, Spinner, Form } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons'
import Select from "react-select";
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import SendMultipleMsg from './SendMultipleMsg';
import { setMultiMsg } from '../Common/ActiveKeySlice';
import TableSkeleton from '../Common/TableSkeleton';
import moment from 'moment';

export const ViewSingleTemplate = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const location = useLocation();
    const templateData = location?.state?.data;
    const userData = useSelector(state=>state?.loginData?.userData?.data);
    const multiMsg = useSelector(state=>state.activeData?.activeMultiMsg);

    const [loader, setLoader] = useState(false);
    const [templateLoader, setTemplateLoader] = useState(false);
    const [data, setData] = useState()
    const [show, setShow] = useState(false);
    const [showReject, setShowReject] = useState(false);
    const [showSendMsg, setShowSendMsg] = useState(false);
    const [consumer, setConsumer] = useState();
    const [consumerList, setconsumerList] = useState([]);
    const [status, setStatus] = useState("Pending");
    const [variables, setVariables] = useState([]);

    useEffect(() => {
        if (data) {
          setVariables(data.variable || []);
        }
      }, [data]);

    // error states
    const [statusErr, setStatusErr] = useState(false);
    const [consumerErr, setConsumerErr] = useState(false);
    const [variableErrors, setVariableErrors] = useState(new Array(variables.length).fill(false));
   
    useEffect(()=>{
        getAllConsumer();
    },[])


    // get All consumer ----------------
    const getAllConsumer = async() =>{
        await fetch(process.env.REACT_APP_BASE_URL + `/consumer?searchField=&pageNo=${1}&docPerPage=${20}`,{
            method:'GET'
        }).then((res)=>res.json()).then((response)=>{
            setconsumerList(response.data.consumers);
            setLoader(false);
        }).catch((err)=>{
            console.log("Error while getting users", err);
            setLoader(false);
        })
    }

    const consumerData = consumerList !== undefined && consumerList !== null && consumerList.length > 0 ? 
                        consumerList?.map((itm)=>{
                            return { label:itm.firstName + "-" + itm.lastName, value:itm.consumerId}
    }) : []

    useEffect(()=>{
        getSingleTemplateData()
    },[templateData?.templateId])

    // get single template data
    const getSingleTemplateData = async() =>{
        setTemplateLoader(true);
        await fetch(process.env.REACT_APP_BASE_URL + `/template/show/${templateData?.templateId}`,{
            method:'GET',
            headers:{'Content-Type':'application/json'}
        }).then((res)=>res.json())
        .then((response)=>{
            setData(response?.data);
            setTemplateLoader(false);
            if(response?.data?.isWhatsappPannelApprove !== "approved"){
                dispatch(setMultiMsg(false));
            }
        })
        .catch((error)=>{
            console.log("Err while getting single template", error);
            setTemplateLoader(false);
        })
    }

    // update status by admin/head ----------------------
    const updateByAdmin = async(templateId, status) =>{
        setLoader(true);
        await fetch(process.env.REACT_APP_BASE_URL + '/template/approveTemplate',{
            method:'POST',
            headers:{'Content-Type':'application/json'},
            body:JSON.stringify({
                templateId:templateId, 
                isApprove:status
            })
        }).then((res)=>res.json())
        .then((response)=>{
            if(response.message=== "Template Approved Successfully...!"){
                toast.success("Status Approved successfully.");
                getSingleTemplateData()
                setShow(false)
                setLoader(false);
            }else if(response.message === "Template Reject Successfully...!"){
                toast.error("Status rejected.");
                getSingleTemplateData()
                setShowReject(false)
                setLoader(false);
            }
        })
        .catch((err)=>{
            console.log("err",err);
            setLoader(false);
        })
    }

    // send message ---------------------------
    const validateVariables = () => {
        let isValid = true;
      
        for (let i = 0; i < variables.length; i++) {
          if (variables[i].trim() === '') {
            setVariableErrors(i, true);
            isValid = false;
          } else {
            setVariableErrors(i, false);
          }
        }
      
        return isValid;
      };
      
      const sendMesgValidate = () => {
        let isValid = true;
      
        if (!consumer) {
          isValid = false;
          setConsumerErr(true);
        } else if (variables.length === 0) {
          isValid = false;
          setVariableErrors(true);
        } else {
          isValid = validateVariables();
        }
      
        return isValid;
      };

      
    const sendMessage = async(templateId) =>{
        setConsumerErr(false);
        setVariableErrors(false);

        if(sendMesgValidate()){
            setLoader(true);
            await fetch(process.env.REACT_APP_BASE_URL + '/conversation/sendMessage',{
                method:'POST',
                headers:{'Content-Type':'application/json'},
                body:JSON.stringify({
                    templateId : templateId,
                    templateInitateById : userData?.userId,
                    templateInitateByContactNo : userData?.contactNo,
                    templateInitateByName:  userData?.name,
                    templateInitateTime : new Date(),
                    consumerId : consumer?.value,
                    parameters : variables
                })
            }).then((res)=>res.json())
            .then((response)=>{
                if(response?.message === "Message sent Successfully"){
                    toast.success("Message sent successfully.");
                    getSingleTemplateData()
                    setShowSendMsg(false)
                    setLoader(false);
                }else if(response?.message === "No credits available for this department")
                    toast.error("Credit point for this department is 0, please add credit point and try again.");
                    setLoader(false);
            })
            .catch((err)=>{
                console.log("err",err);
                setLoader(false);
            })
        }
    }
    /* ----------------------- Handle send Multuple Msg -------------------------*/

     const handleMutliMsg = ()=>{
        dispatch(setMultiMsg(true));
        let scrollArray = [];
        scrollArray.push('sendMultiMsg');
        document.getElementById(scrollArray[0])?.scrollIntoView({ behavior:'smooth'}); 
     }

  return (
    <div className='ViewAllDetails outletPadding'>
    <ToastContainer/>
       <div className="m-3" data-aos="fade-up" data-aos-delay="100">
        <Button className='primaryBtn mb-4' onClick={()=>navigate(-1)} style={{marginLeft:"5px"}}><span >
            <Icon.ArrowLeft className='me-2'/></span>Back</Button>
            <div className="mt-2">
                <Table responsive="sm" bordered>
                    <tbody className="mt-4">
                     {
                        templateLoader ? <TableSkeleton rows={6}  cols={4}/> :
                        <> 
                            <tr>
                                <th>Template Title</th>
                                <td>{data?.templateTitle}</td>
                                <th>Department</th>
                                <td>{data?.departmentName}</td>
                            </tr>
                            <tr>
                                <th>Variables</th>
                                <td>{data?.variable?.map((row) => (row)).join(', ')}</td>
                                <th>Created By</th>
                                <td>{data?.createdBy}</td>
                            </tr>
                            <tr>
                            <th>Variable Counts</th>
                                <td>{data?.noOfVariables}</td>
                                <th>Category</th>
                                <td>{data?.category}</td>
                            </tr>
                            <tr>
                            <th>Message</th>
                                <td style={{width:'50%'}}>{data?.message}</td>
                                <th>Admin Approval</th>
                                <td>
                                    <h6 className={
                                        data?.isApprove === "Pending" ? 'pending' :
                                        data?.isApprove === "Approve" ? 'approve' :
                                        data?.isApprove === "Reject" ? 'reject' :
                                        ''
                                    }>
                                        {data?.isApprove === "Pending" ? "Pending" :
                                        data?.isApprove  === "Approve" ? "Approved" : 
                                        data?.isApprove === "Reject" ? "Rejected" : ""}
                                    </h6>
                                </td>
                                
                            </tr>
                            <tr>
                                <th>Credit Point</th>
                                <td>{data?.credits ? data?.credits : "0"}</td>
                                <th>WhatsApp Panel Approval</th>
                                <td>
                                    <h6 className={
                                        data?.isWhatsappPannelApprove === "pending" || data?.isWhatsappPannelApprove === "submitted" ? 'pending' :
                                        data?.isWhatsappPannelApprove === "approved" ? 'approve' :
                                        data?.isWhatsappPannelApprove === "rejected" ? 'reject' :
                                        ''
                                    }>
                                        {data?.isWhatsappPannelApprove === "pending" || data?.isWhatsappPannelApprove === "submitted" ? "Pending" :
                                        data?.isWhatsappPannelApprove === "approved" ? "Approved" :
                                        data?.isWhatsappPannelApprove === "rejected" ? "Rejected" : " " }
                                    </h6>
                                </td>
                            </tr>
                            <tr>
                                <th>Created Date</th>
                                <td>{moment(data?.createdDate).format('DD-MM-YYYY')}</td>
                                <th>Channel End Date</th>
                                <td>{moment(data?.channelEndDate).format('DD-MM-YYYY')}</td>
                            </tr>
                        </>
                    }
                    </tbody>
                </Table>
            </div>

       <Row className='justify-content-center mt-4'>
        <div className='d-flex flex-column flex-md-row justify-content-center text-center'>
         {
           (data?.isApprove === "Approve" && (data?.isWhatsappPannelApprove === "pending" || data?.isWhatsappPannelApprove === "submitted"))  
               && <h6 style={{ fontWeight:"600", color :"red"}}>Note : Please wait for Whatsapp Panel approval to send a message.</h6>
         }
         {
                data?.isWhatsappPannelApprove === "rejected" &&
                    <h6 style={{ fontWeight:"600", color :"red" }}>Note : Your template has been rejected.</h6>         
         }

         { data?.isApprove==="Pending" &&
            <>
                <Button className='approveBtn mb-3 mb-md-0 me-0 me-md-4' onClick={()=>setShow(true)}>
                            <Icon.CheckCircle className='me-2'/>Status Approve
                        </Button>
                        <Button className='rejectBtn mb-3 mb-md-0' onClick={()=>setShowReject(true)}>
                            <Icon.XCircle className='me-2'/>Status Reject
                        </Button>
            </>  
        }
     
        {
            data?.isWhatsappPannelApprove === "approved" && 
            <>
                <Button className='primaryBtn mb-3 mb-md-0 ms-0 ms-md-4' onClick={()=>setShowSendMsg(true)}>
                    <Icon.Send className='me-2'/>Send Single Message
                </Button> 
                <Button className='primaryBtn ms-0 ms-md-4' onClick={()=>{ handleMutliMsg() }}>
                    <Icon.SendPlus className='me-2'/> Send Multiple Messages
                </Button>
            </> 
        }
         </div>
       </Row>
    </div>


            {/* ---------------------- Send Multiple Message ------------------------ */}
               { multiMsg && data  && 
               <div data-aos="fade-up" id="sendMultiMsg" style={{scrollMarginTop:'-50vh'}}>
                    <SendMultipleMsg data={data} />
                </div>
               }



    {/* ------Approve status Modal--------- */}
            <Modal size='md' show={show} backdrop='static' keyboard={false} centered onHide={()=>setShow(false)}>
                <Modal.Header closeButton>
                <Modal.Title> <h4 className='mb-0'><Icon.CheckCircle size={20} className='me-2'/>Approve Status</h4> </Modal.Title>
                </Modal.Header>
                <Modal.Body className='justify-content-center'>
                    <Row>
                    <h6>Are you sure you want to approve the status !</h6>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button 
                        className='approveBtn' 
                        disabled={loader}
                        onClick={()=>updateByAdmin(data?.templateId,"Approve")}> 
                        { loader ? <Spinner size='sm'/> : <Icon.CheckCircle className='me-2'/> }Approve</Button>
                </Modal.Footer>
            </Modal>

            {/* ------Reject status Modal--------- */}
           <Modal size='md' show={showReject} backdrop='static' keyboard={false} centered onHide={()=> setShowReject(false)}>
                <Modal.Header closeButton>
                <Modal.Title> <h4 className='mb-0'><Icon.XCircle size={20} className='me-2'/>Reject Status</h4> </Modal.Title>
                </Modal.Header>
                    <Modal.Body className='justify-content-center'>
                        <Row>
                        <h6>Are you sure you want to reject the status !</h6>
                        </Row>
                    </Modal.Body>
                <Modal.Footer>
                    <Button 
                        className='rejectBtn' 
                        disabled={loader}
                        onClick={()=>updateByAdmin(data?.templateId,"Reject")}> 
                        { loader ? <Spinner size='sm'/> : <Icon.XCircle className='me-2'/> }Reject</Button>
                </Modal.Footer>
            </Modal>


            {/* ---------send message modal------ */}
            <Modal size='lg' show={showSendMsg} backdrop='static' keyboard={false} centered onHide={()=> setShowSendMsg(false)}>
                <Modal.Header closeButton>
                <Modal.Title> <h4 className='mb-0'><Icon.Send size={20} className='me-2'/>Send Message</h4> </Modal.Title>
                </Modal.Header>
                <Modal.Body className='justify-content-center'>
                    <Row>
                    <Col md={6}>
                    <Form.Group className='mb-3'>
                        <Form.Label>Template Title</Form.Label>
                        <Form.Control value={data?.templateTitle}>
                        </Form.Control>
                    </Form.Group>
                    </Col>

                    <Col md={6}>
                    <Form.Group className='mb-3'>
                        <Form.Label>Consumer Name</Form.Label>
                        <Select 
                            options={consumerData} 
                            onChange={(selected)=>{
                                setConsumerErr(false)
                                setConsumer(selected)
                            }}
                        />
                        {consumerErr ? <p className='errMsg'>Please select consumer</p>: ''}
                    </Form.Group>
                    </Col>

                    <Col md={12}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Message</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={4}
                                value={data?.message} />
                        </Form.Group>
                    </Col>

                    <Col md={4}>
                        <Form.Group className='mb-3'>
                            <Form.Label>No of Variables</Form.Label>
                            <Form.Control
                                value={data?.noOfVariables} />
                        </Form.Group>
                    </Col>

                    <Row>
                        {variables?.map((variable, index) => (
                        <Col md={4}>
                        <Form.Group key={index} className='mb-3' style={{ display: 'inline-block', marginRight: '10px' }}>
                            <Form.Label>{`Variable ${index + 1}`}</Form.Label>
                            <Form.Control
                                type="text"
                                value={variable}
                                name='parameters'
                                onChange={(e) => {
                                    const updatedVariables = [...variables];
                                    updatedVariables[index] = e.target.value;
                                    setVariables(updatedVariables);

                                    const updatedErrors = [...variableErrors];
                                    if (e.target.value.trim() === '') {
                                        updatedErrors[index] = true; 
                                    } else {
                                        updatedErrors[index] = false;
                                    }
                                    setVariableErrors(updatedErrors);
                                    }}
                                />
                            {variableErrors[index] && (<p className='errMsg'>Variable {index + 1} cannot be empty</p>)}
                        </Form.Group>
                        </Col>
                        ))}
                    </Row>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button 
                        className='primaryBtn' 
                        disabled={loader}
                        onClick={()=>sendMessage(data?.templateId,data?.credits)}> 
                        { loader ? <Spinner size='sm'/> : <Icon.Send className='me-2'/>}Send Message</Button>
                </Modal.Footer>
            </Modal>
    </div>
  )
}
