import React from 'react';


const TableSkeleton = ({rows, cols}) => {

    const rowsLimit = Array.from({ length: rows }, (_, index) => index);
    const colsLimit = Array.from({ length: cols }, (_, index) => index);
  return (
    <>
    {
        rowsLimit?.map((itm)=>(
            <tr key={itm} className="tr">
                {
                    colsLimit.map((itm)=>(
                        <td className="td"><div className="loader"></div></td>
                    ))
                }
            </tr>
        ))
    }  
    </>
  )
}

export default TableSkeleton;