import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Container, Modal, Row, Form, Spinner, Image } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import Select from "react-select";
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ReactS3 from '../../../Utils/ReactS3';
import { S3Context } from '../../Common/S3Provider';

const EditUserModel = ({show, close, editData, getAllUsers}) => {
    const {s3Data} = useContext(S3Context);
    const [loader, setLoader] = useState(false);

    const [id, setId] = useState();
    const [profileImg, setProfileImg] = useState('')
    const [name, setName] = useState();
    const [mobile, setMobile] = useState();
    const [whatsaapNo, setWhatsaapNo] = useState();
    const [pass, setPass] = useState();
    const [email, setEmail] = useState();
    const [dept, setDept] = useState();
    const [desg, setDesg] = useState();
    const [role, setRole] = useState();
    const [category, setCategory] = useState()

    const [profileImgErr, setProfileImgErr] = useState(false)
    const [nameErr, setNameErr] = useState(false);
    const [mobileErr, setMobileErr] = useState(false);
    const [whatsappErr, setWhatsappErr] = useState(false)
    const [passErr, setPassErr] = useState(false);
    const [passLengthErr, setpassLengthErr] = useState(false);
    const [emailErr, setEmailErr] = useState(false);
    const [deptErr, setDeptErr] = useState(false);
    const [desgErr, setDesgErr] = useState(false);
    const [roleErr, setRoleErr] = useState(false);

    const commonData = useSelector(state=> state.commonData.commonData)

    const editValidate=()=>{
        let isValid = true;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        
        if(!profileImg){
            isValid = false;
            setProfileImgErr(true); 
        }else if(!name){
            isValid = false;
            setNameErr(true); 
        }else if(!mobile || mobile.length<10){
            isValid = false;
            setMobileErr(true);
        }else if(!whatsaapNo || whatsaapNo.length<10){
            isValid = false;
            setWhatsappErr(true);
        }else if(!email || !emailRegex.test(email)){
            isValid = false;
            setEmailErr(true);
        }else if(!dept){
            isValid = false;
            setDeptErr(true);
        }else if(desg?.length === 0){
            isValid = false;
            setDesgErr(true);
        }else if(!role){
            isValid = false;
            setRoleErr(true);
        }
        return isValid;
    }

    const closeEditModal=()=>{
        setProfileImgErr(false)
        setNameErr(false);
        setMobileErr(false);
        setWhatsappErr(false)
        setPassErr(false);
        setpassLengthErr(false)
        setEmailErr(false);
        setDeptErr(false);
        setDesgErr(false);
        setRoleErr(false);
        setLoader(false)

        close()
    }

    useEffect(() =>{
        if (editData) {
            setId(editData?._id);
            setProfileImg(editData?.profileImg)
            setName(editData?.name);
            setMobile(editData?.contactNo);
            setWhatsaapNo(editData?.whatsappNo)
            setPass()
            setEmail(editData?.email);
            const selectedDepart = commonData?.departments.find((option) => option.label === editData?.departmentName)
            setDept(selectedDepart);
            setDesg(editData?.designation);
            const selectedUserType = commonData?.userTypes.find((option) => option.label === editData?.userType)
            setRole(selectedUserType);
            const selectedCategory = commonData?.categories?.find((option) => option.label === editData?.categoryName)
            setCategory(selectedCategory)  
        }
    },[editData])

    const editUser= async ()=>{
        setLoader(true);

        if(editValidate()){
        await fetch(process.env.REACT_APP_BASE_URL + '/user/update',{
            method:'POST',
            headers:{'Content-Type':'application/json'},
            body:JSON.stringify({
                id:id,
                profileImg:profileImg,
                name:name,
                contactNo:mobile,
                password:pass,
                email:email,
                departmentName:dept?.label,
                departmentId:dept?.value,
                categoryId: category?.value,
                categoryName:category?.label,
                userTypeKey: role?.value,
                userType:role?.label,
                designation:desg,
                
            })
        }).then((res)=>res.json()).then((response)=>{
            console.log("response",response);
            if(response.message === "Record Updated Successfully...!"){
                toast.success("User updated successfully");
                getAllUsers()
                setLoader(false);
                closeEditModal();
            }
        }).catch((err)=>{
            console.log("Err while updating user", err);
            setLoader(false);
        }) 
    }
    else{
        setLoader(false);
    }
    }

    const handleProfileImg = async(e)=>{
        let result = await ReactS3(e.target.files[0], s3Data,"userImageDocs");
        setProfileImg(result);
    }

  return (
    <div>
      <Modal size='lg' show={show} backdrop='static' keyboard={false} centered onHide={closeEditModal}>
        <Modal.Header closeButton>
            <Modal.Title> 
                <h4 className='mb-0'><Icon.PencilSquare size={20} className='me-2'/>Edit User</h4>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row className='justify-content-center'>
                <Col md={5}>
                    <Form.Group className='mb-3'>
                        {  profileImg ? 
                        <>
                            <Form.Label>Uploaded Image<span className='errMsg'>*</span></Form.Label>
                            <div className='uploadFile'>
                                <img src={profileImg} alt="profile Image"/>
                                <h6>{String(profileImg)?.split('/')[String(profileImg)?.split('/').length - 1]}</h6>
                                    <Icon.XLg className='me-2' size={20} onClick={()=>setProfileImg('')}/>
                            </div>
                        </> : 
                        <>
                            <Form.Label>Upload Profile Image<span className='errMsg'>*</span></Form.Label>
                            <Form.Control type='file'onChange={(e)=> { handleProfileImg(e); setProfileImgErr(false); }} />
                        </>
                        }
                    {profileImgErr ? <p className='errMsg'>Please select profile image</p> : ''}
                    </Form.Group> 
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    <Form.Group className='mb-3'>
                    <Form.Label>Name<span className='errMsg'>*</span></Form.Label>
                    <Form.Control placeholder='Enter name' value={name} 
                                onChange={(e)=> {if (!e.target.value.match(/[0-9+@#$&%!~=^_:(){}\[\]|/*./\//\-/?<>,;`'""/]/) &&  
                                e.target.value.trim() ) {
                                setName(e.target.value);
                                } else if (e.target.value.length === 0) {
                                setName(e.target.value);
                                }
                             e.target.value.length > 0 ? setNameErr(false) : setNameErr(true); }} />
                            {nameErr ? <p className='errMsg'>Please enter valid name</p> : ''}
                    </Form.Group> 
                </Col>

                <Col md={4}>
                    <Form.Group className='mb-3'>
                    <Form.Label>Mobile<span className='errMsg'>*</span></Form.Label>
                    <Form.Control placeholder='Enter mobile number' name='mobile' value={mobile} 
                    disabled={true} 
                            //     onChange={(e)=> {if (!e.target.value.match(/[A-Za-z+@#$&%!~=*./\s/g/\-/?<>,"/]/) && 
                            //                         e.target.value.length <= 10) {
                            //                         setMobile(e.target.value);
                            //                     } else if (e.target.value.length === 0) {
                            //                         setMobile(e.target.value);
                            //                     }
                            //     e.target.value.length > 0 ? setMobileErr(false) : setMobileErr(true)
                            // }}

                            />
                            {/* {mobileErr ? <p className='errMsg'>Please enter valid mobile number</p> : ''} */}
                    </Form.Group>
                </Col>

                {/* <Col md={4}>
                    <Form.Group className='mb-3'>
                    <Form.Label>Whatsapp number</Form.Label>
                    <Form.Control placeholder='Enter whatsapp number' name='whatsaapNo' value={whatsaapNo}/>
                    </Form.Group>
                </Col> */}
            
                <Col md={4}>
                    <Form.Group className='mb-3'>
                    <Form.Label>Email<span className='errMsg'>*</span></Form.Label>
                    <Form.Control placeholder='Enter email' value={email} 
                                onChange={(e)=>{ setEmail(e.target.value);
                                e.target.value.length > 0 ? setEmailErr(false) : setEmailErr(true) }}/>
                            {emailErr ? <p className='errMsg'>Please enter valid email id</p> : ''}
                    </Form.Group>
                </Col>

                <Col md={4}>
                    <Form.Group className='mb-3'>
                    <Form.Label>Department<span className='errMsg'>*</span></Form.Label>
                    <Select
                        isMulti={false}
                        isSearchable={true}
                        name='departmentName'
                        value={dept}
                        options={commonData?.departments}
                        onChange={(option) => {
                            setDeptErr(false)
                            setDept(option);
                        }}/>
                        {deptErr ? <p className='errMsg'>Please select any department</p> :''}
                    </Form.Group> 
                </Col>

                <Col md={4}>
                    <Form.Group className='mb-3'>
                    <Form.Label>Designation<span className='errMsg'>*</span></Form.Label>
                    <Form.Control placeholder='Enter Designation' value={desg} 
                                onChange={(e)=>{
                                    setDesg(e.target.value);
                                e.target.value.length > 0 ?  setDesgErr(false) : setDesgErr(true) 
                            }}/>
                            { desgErr ? <p className='errMsg'>Please enter any designation</p> : ''}
                    </Form.Group> 
                </Col>
                
                <Col md={4}>
                    <Form.Group className='mb-3'>
                    <Form.Label>Role<span className='errMsg'>*</span></Form.Label>
                    <Select
                            isMulti={false}
                            isSearchable={true}
                            value={role}
                            name='userTypeKey'
                            options={commonData?.userTypes}
                            onChange={(option) => {
                                setRoleErr(false)
                                setRole(option);
                            }}/>
                            { roleErr ? <p className='errMsg'>Please select any role</p> : ''}
                    </Form.Group> 
                </Col>

                <Col md={4}>
                    <Form.Group className='mb-3'>
                    <Form.Label>Category</Form.Label>
                        <Select
                            isMulti={false}
                            isSearchable={true}
                            name='categoryName'
                            value={category}
                            options={commonData?.categories}
                            onChange={(option) => {
                                setCategory(option);
                            }}
                            />
                    </Form.Group> 
                </Col>
            </Row>
                       
        </Modal.Body>

        <Modal.Footer>
            <Button className='primaryBtn' onClick={editUser} disabled={loader}> 
            { loader ? <Spinner size='sm'/> : <Icon.PencilSquare className='me-2'/> }Edit User</Button>
        </Modal.Footer>
    </Modal>
    </div>
  )
}

export default EditUserModel
