import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  templateList: [],
  loading: "idle",
};

// for getallhometask
export const getAllTemplate = createAsyncThunk(
  "getAllTemplatePromise",
  async (_, { rejectWithvalue }) => {
    const response = await fetch(process.env.REACT_APP_BASE_URL + "/template", {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });

    console.log("response", response);
    try {
      const result = await response.json();
      console.log("New result 123", result);
      return result;
    } catch (error) {
      return rejectWithvalue(error);
    }
  }
);

const templateSlice = createSlice({
  name: "template",
  initialState,
  // reducers: {},
  extraReducers: (builder) => {
    builder
      //for getallhometask
      .addCase(getAllTemplate.pending, (s, a) => {
        s.loading = "pending";
      })
      .addCase(getAllTemplate.rejected, (s, a) => {
        s.loading = "idle";
      })
      .addCase(getAllTemplate.fulfilled, (s, a) => {
        s.templateList = a.payload;
        s.loading = "success";
      });
  },
});

// export const { } = departmentSlice.actions;
export default templateSlice.reducer;
