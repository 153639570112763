import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    activeChatData: '',
    onlineUsers:[]
};


const chatSlice = createSlice({
  name: 'activeChatData',
  initialState,
  reducers:{
    setActiveChat(state, action){
      state.activeChatData = action.payload
    },
    setOnlineUsers(state, action){
      state.onlineUsers = action.payload
    }
  },
});


export const {setActiveChat,setOnlineUsers} = chatSlice.actions;
export default chatSlice.reducer;