import React from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import GoaGov from '../../Assets/GoaGov.svg'
import Community from '../../Assets/Community.svg';
import * as Icon from 'react-bootstrap-icons';
import Facebook from '../../Assets/FaceBook.svg'
import styled from 'styled-components';
import { ConnectButton } from '360dialog-connect-button';

const Onboard360 = () => {

  const handleCallback = callbackObject => {
    /* The callback function returns the client ID as well as all channel IDs, for which you're enabled to fetch the API key via the Partner API */

    console.log('client ID: ' + callbackObject.client);
    console.log('channel IDs: ' + callbackObject.channels);
  };

  const StyledConnectButton = styled(ConnectButton)`
  outline: none;
  background: linear-gradient(45deg, #0338e7, #1073ff) !important;
  color: white;
  padding: 8px 16px;
  border-radius: 3px;
  margin-top: 0;
  border: none;
`;


  return (
    <div className='Onboard360'>
        <Container fluid  className='h-100 d-flex justify-content-center align-items-center p-0'>
            <Row className='h-100 w-100 flex-column'>
              <Col md={7} className="content h-100 py-5 px-3 ">
              <div className='heading'>
                <div className='d-flex'>
                  <Icon.ArrowRightCircleFill className='me-2 mt-1 d-none d-md-block' size={18} color='#ffffff'/>
                  <h5 className='mb-0'> Following prerequisite needs to be considered before onboarding process - </h5>
                </div>

                <Row className='mt-0 mt-md-3 ps-2'>
                  <Col md={12}>
                      <Card className='h-100'>
                            <h6><img src={Facebook} className='me-2 pt-1'/>1. Social Media:</h6>
                            <ul>
                              <li>Facebook Business Verified account and page should be needed</li>
                            </ul>
                          </Card>
                  </Col>

                <Col md={6}>
                    <Card className='h-100'>
                      <h6><Icon.Building className='me-2'/>2. Company Information:</h6>
                      <ul>
                        <li>Meta business account</li>
                        <li>Business name</li>
                        <li>Business website or profile page</li>
                        <li>Country</li>
                      </ul>
                    </Card>
                    </Col>

                    <Col md={12}>
                      <Card className='h-100'>
                      <h6><Icon.Whatsapp className='me-2'/>3. WhatsApp business profile</h6>
                      <ul>
                        <li>WhatsApp business account name</li>
                        <li>WhatsApp Business profile display name</li>
                        <li>Business Category</li>
                        <li>Business description</li>
                        <li>Website (This website will be visible to people on WhatsApp.)</li>
                      </ul>
                      </Card>
                    </Col>

                    <Col md={12}>
                    <Card className='h-100 mb-5 mb-md-0'>
                      <h6><Icon.Telephone className='me-2'/>4. Contact Information:</h6>
                      <ul>
                        <li>Name</li>
                        <li>Email Address (Company domain email id)</li>
                        <li>Contact Number (Make sure taken contact number should not be use any WhatsApp or any WhatsApp business account)</li>  
                      </ul>
                    </Card>
                    </Col>

                  </Row>
              </div>
              </Col>
              <Col md={5} className='imagebox h-100 mb-5 mb-md-0'>
                  <img src={GoaGov} alt="Goa" width={100} className='my-0 my-md-3' />
                  <h2>WHATSAPP BUSINESS ONBOARDING</h2> 
                  <ConnectButton partnerId={'jRl0IwPA'}  queryParameters={{redirect_url:"https://itgoa.foxberry.live/onboarding/thank-you"}} callback={handleCallback}
                   label="Onboard Now" className='primaryBtn mx-auto'/>
                 <img src={Community} alt="community" className='img-fluid mt-3' style={{width:'100%'}}/>
              </Col>
            </Row>
            
        </Container>


    </div>
  )
}

export default Onboard360;