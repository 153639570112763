import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
    // templateList: [],
    // userList : [],
    // userCategoryList : [],
    commonData : []
}

// for getallhometask
// export const getAllTemplates = createAsyncThunk("getAllTemplatePromise", async (_, { rejectWithvalue }) => {
//     const response = await fetch(process.env.REACT_APP_BASE_URL + '/department', {
//         method: 'GET',
//         headers: { "Content-Type": "application/json" },
//     })
//     try {
//         const result = await response.json()
//         console.log("New result", result)
//         return result
//     } catch (error) {
//         return rejectWithvalue(error)
//     }
// })

// export const getAllUsers = createAsyncThunk("getAllUsersPromise", async (data, { rejectWithvalue }) => {
//     const response = await fetch("http://api.itgoa.foxberry.live/v1/user?pageNo={1}&docPerPage=10", {
//         method: 'POST',
//         headers: { "Content-Type": "application/json" },
//         body:JSON.stringify(data)
//     })
//     try {
//         const result = await response.json()
//         console.log("New result", result)
//         return result
//     } catch (error) {
//         return rejectWithvalue(error)
//     }
// })

const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        setCommonData(state, action){
        state.commonData = action.payload
    }},
});

export const {setCommonData } = commonSlice.actions;
export default commonSlice.reducer;
