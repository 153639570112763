import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Modal, Row, Form, Spinner, Image, InputGroup, FormControl } from 'react-bootstrap';
import Pagination from '../Common/Pagination'; 
import * as Icon from 'react-bootstrap-icons';
import { ToastContainer, toast } from 'react-toastify';
import Select from "react-select";
import edit from "../../Assets/edit.svg";
import deletes from "../../Assets/delete.svg";
import AddUserModel from './Modal/AddUserModel';
import EditUserModel from './Modal/EditUserModel';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate} from 'react-router-dom';
import TableSkeleton from '../Common/TableSkeleton';

const ManageUsers = () => {
    const navigate = useNavigate()
    const userState = useSelector(state=>state?.loginData?.userData?.data);

    const [showAdd, setShowAdd]  = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showDel, setShowDel] = useState(false);
    const [showFilter, setShowFilter] = useState(false)
    const [loader, setLoader] = useState(false);
    const [editData, setEditData] = useState()
    const [searchFieldErr, setSearchFieldErr] = useState(false)

    // States for form Select option ------
    const [users, setUsers] = useState();

    // States for Pagination ----------
    const [pageNo, setPageNo] = useState(1);
    const [docPerPage, setDocPerPage] = useState(10);
    const [noOfPages, setNoOfPages] = useState();

    // filter States
    const [filterName, setFilterName] = useState("")
    const [filterMobileNo, setFilterMobileNo] = useState("")
    const [filterWhatsaapNo, setFilterWhatsaapNo] = useState("")
    const [filterDepart, setFilterDepart] = useState("")
    const [filterDesign, setFilterDesign] = useState("")
    const [filterRole, setFilterRole] = useState("")
    const [filterCateg, setFilterCateg] = useState("")

  // States of Delete user -----------------------
    const [delId, setDelId] = useState();

    // search field
    const [searchField, setSearchField] = useState("")

    const [filterData, setFilterData] = useState()

    useEffect(()=>{
        userFilterModel()
    },[])
    
    useEffect(()=>{
        getAllUsers();
    },[pageNo, docPerPage])

    // close add model
    const handleCloseAdd = () =>{
        setShowAdd(false)
    }

    // show edit model
    const handleShowEdit = (id) =>{
        setShowEdit(true)
        setEditData(id)
    }

    // close edit model
    const handleCloseEdit = () =>{
        setShowEdit(false)
    }

    // close filter model
    const closeFilterModel = () => {
        setShowFilter(false)
    }

    const userFilterModel = async() =>{
        await fetch(process.env.REACT_APP_BASE_URL + "/user/filterOptions",{
            method:'GET',
            headers:{'Content-Type':'application/json'}
        }).then((res)=>res.json()).then((response)=>{
            setFilterData(response.data)
            setLoader(false);
        }).catch((err)=>{
            console.log("Err while adding user", err);
            setLoader(false);
        }) 
    }

    const getAllUsers = async()=>{
        setLoader(true);
        await fetch(process.env.REACT_APP_BASE_URL + `/user?pageNo=${pageNo}&docPerPage=${docPerPage}`,{
            method:'POST',
            headers:{'Content-Type':'application/json'},
            body:JSON.stringify({
                departmentId :userState?.userTypeKey === "admin" ? "" : userState?.departmentId,
                searchField : searchField ? searchField : ""
            })
        }).then((res)=>res.json()).then((response)=>{
            setUsers(response?.data?.users);
            setNoOfPages(response?.data?.noOfPages);
            setLoader(false);
        }).catch((err)=>{
            console.log("Error while getting users", err);
            setLoader(false);
        })
    }

    const getFilterUsers = async() =>{
        setLoader(true);
        await fetch(process.env.REACT_APP_BASE_URL + `/user/filter?pageNo=${pageNo}&docPerPage=${docPerPage}`,{
            method:'POST',
            headers:{'Content-Type':'application/json'},
            body:JSON.stringify({
                userId: "",
                name: filterName?.value ?? "",
                contactNo: filterMobileNo?.value ?? "",
                whatsappNo: filterWhatsaapNo?.label ?? "",
                userType: filterRole?.value ?? "",
                categoryId: filterCateg?.value ?? "",
                departmentId: filterDepart?.value ?? "",
                designation: filterDesign?.value ?? ""
            })
        }).then((res)=>res.json()).then((response)=>{
            setUsers(response?.data?.users);
            closeFilterModel()
            setNoOfPages(response.data.noOfPages);
            setLoader(false);
        }).catch((err)=>{
            console.log("Error while getting users", err);
            setLoader(false);
        })
    }

    const deleteUser=async()=>{
        setLoader(true);
            await fetch(process.env.REACT_APP_BASE_URL + `/user/delete/${delId}`,{
                method:'GET',
                headers:{'Content-Type':'application/json'}
            }).then((res)=>res.json()).then((response)=>{
                if(response.message === "Record Delete Successfully...!"){
                    toast.success("User deleted successfully !");
                    setShowDel(false);
                    getAllUsers();
                }
                setLoader(false);
            }).catch((err)=>{
                console.log("Err while adding user", err);
                setLoader(false);
            }) 
        }
    
        // function for pagination
    const handlePageChange = (pageNumber) => {
            setPageNo(pageNumber);
    };
    
    const handleDocsPerPage = (docsPageProp) => {
        setPageNo(1)
        setDocPerPage(docsPageProp);
    }

    const clearFilter = () =>{
        setFilterName("")
        setFilterMobileNo("")
        setFilterWhatsaapNo("")
        setFilterCateg("")
        setFilterDepart("")
        setFilterDesign("")
        setFilterRole("")
    }

    useEffect(() => {
        if (searchField?.length === 0) {
            getAllUsers();
        }
    }, [searchField]);

    const handleSearch = () => {
        if (searchField.trim() === "") {
            setSearchFieldErr(true);
        } else {
            setSearchFieldErr(false);
            getAllUsers();
        }
    };

  return (
    <div className='ManageUsers outletPadding'>
        <ToastContainer/>
        <AddUserModel show={showAdd} close={handleCloseAdd} getAllUsers = {getAllUsers}/>
        <EditUserModel show={showEdit} close = {handleCloseEdit} editData={editData} getAllUsers = {getAllUsers}/>
        <Container>
          
                <Row className='justify-content-between mb-4'>
                    <Col md={4}>
                        <div className='d-flex'>
                            <Form.Control className='me-2'
                                placeholder="Search by Name or Whatsapp No."
                                value={searchField}
                                onChange={(e) => {
                                    setSearchField(e.target.value);
                                    searchField.length < 0 ? 
                                    setSearchFieldErr(true) : 
                                    setSearchFieldErr(false)
                                }}/>
                                <Button onClick={() => handleSearch()}><Icon.Search/></Button>
                            </div>        
                            <p className='errMsg'>
                                {searchFieldErr ? "Please Enter Name or WhatsApp no." : null}
                            </p>
                    </Col>

                    <Col md={5} className='d-flex flex-column flex-md-row justify-content-end align-items-start'>
                             <Button className='primaryBtn me-0 me-md-3 ms-auto' onClick={()=>setShowAdd(true)}>
                                <Icon.PersonAdd className='me-2'/>Add User
                            </Button>

                            <Button className='primaryBtn mt-3 mt-md-0 ms-auto ms-md-0' onClick={()=>navigate("/dashboard/manage-category")}>
                               <Icon.PersonGear className='me-2'/> Manage User Category
                            </Button>
                           
                    </Col>
                </Row>

            <div className="outer-wrapper mx-auto" data-aos='fade-up' data-aos-delay="100">

                <div className="table-wrapper">
                    <table>
                        <thead>
                            <th>Sr No</th>
                            <th>Profile</th>
                            <th>Name</th>
                            <th>Mobile No</th>
                            <th>Whatsapp No</th>
                            <th>Email</th>
                            <th>Department</th>
                            <th>Designation</th>
                            <th>Category</th>
                            <th>Role</th>
                            <th>Actions</th>
                        </thead>
                        {
                            loader ? <TableSkeleton rows={10} cols={11}/> :
                            users!== null && users !== undefined && users.length > 0 ? users.map((itm, index) => {

                            const { name, contactNo, email,  designation, userType, departmentName,
                                    whatsappNo, categoryName, profileImg} = itm

                            return (
                                <tr key={Math.random() * 999999999}>
                                    <td>{pageNo !== 1 ? (<> {index + 1 + docPerPage * (pageNo - 1)}</>) : <>{index + 1}</>}</td>
                                    <td><img src={profileImg} alt='profileImg' width={40}/></td>
                                    <td>{name}</td>
                                    <td>{contactNo}</td>
                                    <td>{whatsappNo}</td>
                                    <td>{email}</td>
                                    <td>{departmentName}</td>
                                    <td>{designation}</td>
                                    <td>{categoryName ? categoryName :'-' }</td>
                                    <td>{userType}</td>
                                    <td>
                                        <img src={edit} className="me-3 icon" style={{ cursor:'pointer' }} 
                                            onClick={()=>{handleShowEdit(itm)}}/>
                                        <img src={deletes} className="me-3 icon" style={{ cursor:'pointer' }} 
                                            onClick={()=>{setShowDel(true); setDelId(itm?._id)}}/>
                                    </td>
                                </tr>
                                    )
                                })
                                    : 'No data Found'
                            }
                        </table>
                    </div>
                </div>
                <Pagination currentPage={pageNo} totalPages={noOfPages}
                    onPageChange={handlePageChange} onDocsPerPage={handleDocsPerPage} docPerPage={docPerPage}/>
          

    {/* ---------------- Delete User Modal ----------------------------------------------------------- */}
    <Modal size='md' show={showDel} backdrop='static' keyboard={false} centered onHide={() => setShowDel(false)}>
        <Modal.Header closeButton>
            <Modal.Title> <h4 className='mb-0'><Icon.Trash size={20} className='me-2'/>Delete User</h4> </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <h4 className='text-center mb-0'>Are you sure to delete ?</h4>    
        </Modal.Body>

        <Modal.Footer>
            <Button className='primaryBtn' onClick={deleteUser} disabled={loader}> 
            { loader ? <Spinner size='sm'/> : <Icon.Trash className='me-1'/> }Delete</Button>
        </Modal.Footer>
    </Modal>

    {/*------------------Filter User Model ----------------------------------------------------------- */}
    {/* <Modal size='lg' show={showFilter} backdrop='static' keyboard={false} centered onHide={closeFilterModel}>
        <Modal.Header closeButton>
            <Modal.Title> <h4 className='mb-0'><Icon.Funnel size={20} className='me-2'/>Filter</h4> </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row>
                <Col md={4}>
                    <Form.Group className='mb-3'>
                    <Form.Label>Name</Form.Label>
                    <Select
                        isMulti={false}
                        isSearchable={true}
                        name='name'
                        value={filterName}
                        options={filterData?.userNameOptions !== undefined && filterData?.userNameOptions !== null &&
                                filterData?.userNameOptions.length > 0 ? filterData?.userNameOptions : []}
                        onChange={(option) => {
                            setFilterName(option);
                        }}/>
                    </Form.Group> 
                </Col>

                <Col md={4}>
                    <Form.Group className='mb-3'>
                    <Form.Label>Mobile</Form.Label>
                    <Select
                        isMulti={false}
                        isSearchable={true}
                        name='mobile'
                        value={filterMobileNo}
                        options={filterData?.mobileNoOptions !== undefined && filterData?.mobileNoOptions !== null &&
                                filterData?.mobileNoOptions.length > 0 ? filterData.mobileNoOptions : []}
                        onChange={(option) => {
                            setFilterMobileNo(option);
                        }}/>
                    </Form.Group>
                </Col>

                <Col md={4}>
                    <Form.Group className='mb-3'>
                    <Form.Label>Whatsapp number</Form.Label>
                    <Select
                        isMulti={false}
                        isSearchable={true}
                        name='whatsappNo'
                        value={filterWhatsaapNo}
                        options={filterData?.whatsappNoOptions !== undefined && filterData?.whatsappNoOptions !== null
                                && filterData?.whatsappNoOptions.length > 0 ? filterData?.whatsappNoOptions : []}
                        onChange={(option) => {
                            setFilterWhatsaapNo(option);
                        }}/>
                    </Form.Group>
                </Col>
            </Row>
                            
            <Row>
                <Col md={4}>
                    <Form.Group className='mb-3'>
                    <Form.Label>Department</Form.Label>
                    <Select
                        isMulti={false}
                        isSearchable={true}
                        name='departmentId'
                        value={filterDepart}
                        options={filterData?.departmentOptions !== undefined && filterData?.departmentOptions !== null &&
                                filterData?.departmentOptions.length > 0 ? filterData?.departmentOptions :[]}
                        onChange={(option) => {
                            setFilterDepart(option);
                        }}/>
                    </Form.Group> 
                </Col>

                <Col md={4}>
                    <Form.Group className='mb-3'>
                    <Form.Label>Designation</Form.Label>
                    <Select
                        isMulti={false}
                        isSearchable={true}
                        name='designation'
                        value={filterDesign}
                        options={filterData}
                        onChange={(option) => {
                            handleGender(option);
                                }}
                    />
                    </Form.Group> 
                </Col>

                <Col md={4}>
                    <Form.Group className='mb-3'>
                    <Form.Label>Role</Form.Label>
                    <Select
                        isMulti={false}
                        isSearchable={true}
                        name='userType'
                        value={filterRole}
                        options={filterData?.roleOptions !== undefined && filterData?.roleOptions !== null && 
                                filterData?.roleOptions.length > 0 ? filterData?.roleOptions : []}
                        onChange={(option) => {
                            setFilterRole(option);
                        }}/>
                    </Form.Group> 
                </Col>
            </Row>

            <Row>
                <Col md={4}>
                    <Form.Group className='mb-3'>
                    <Form.Label>Category</Form.Label>
                    <Select
                        isMulti={false}
                        isSearchable={true}
                        name='categoryId'
                        value={filterCateg}
                        options={filterData?.categoryOptions !== undefined && filterData?.categoryOptions !== null && 
                                filterData?.categoryOptions.length > 0 ? filterData?.categoryOptions : []}
                        onChange={(option) => {
                            setFilterCateg(option);
                        }}/>
                    </Form.Group> 
                </Col>
            </Row>
        </Modal.Body>

        <Modal.Footer>
            <Button className='primaryBtn' onClick={clearFilter} disabled={loader}><Icon.XCircle className='me-2'/>Reset</Button>
            <Button className='primaryBtn' onClick={getFilterUsers} disabled={loader}> 
            { loader ? <Spinner size='sm'/> : <Icon.Funnel className='me-2'/> }Submit</Button>
            </Modal.Footer>
    </Modal>             */}
        
        </Container>
    </div>
  )
}

export default ManageUsers;