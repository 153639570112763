import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    activeNumberKey: '',
    activeMultiMsg:false
};


const sidebarSlice = createSlice({
  name: 'activeKey',
  initialState,
  reducers:{
    setActiveKey(state, action){
      state.activeNumberKey = action.payload
    },
    setMultiMsg(state, action){
      state.activeMultiMsg=action.payload;
    }
  },
});


export const {setActiveKey, setMultiMsg} = sidebarSlice.actions;
export default sidebarSlice.reducer;