import S3 from 'react-aws-s3';
import imageCompression from 'browser-image-compression';
window.Buffer = window.Buffer || require("buffer").Buffer;

const ReactS3 = async(file, credentials, directory)=>{
    try{
        let S3Link ;
        //---------------- Config ---------------------------------
            const config = {
                bucketName: credentials?.bucketName,
                dirName: directory,
                region: credentials?.region,
                accessKeyId: credentials?.accessKeyId,
                secretAccessKey: credentials?.secretAccessKey,
                s3Url: credentials?.s3Url
            };
            
            console.log("config", config);


              //---- Setting up aws S3 Client -----------
                const ReactS3Client = new S3(config);
                if(file?.type?.includes('image')){
                    // console.log('originalFile instanceof Blob', file instanceof Blob); 
                    // console.log(`originalFile size ${file.size / 1024 / 1024} MB`);
                      const options = {
                        maxSizeMB: 1,
                        maxWidthOrHeight: 1920,
                        useWebWorker: true,
                      }

                      const compressedFile = await imageCompression(file, options);
                      // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); 
                      // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); 
                      // console.log("Compressed file", compressedFile);
                      const compressedFileName = compressedFile?.name + "/" + Date.now();
                     await ReactS3Client.uploadFile(compressedFile, compressedFileName)
                        .then((res) => {
                            if (res.status === 204) {
                                S3Link = res?.location;
                             }else {
                                console.log("AWS Error");
                            }
                        })
                        .catch((err) => {
                            console.error("Err while uploading img on S3", err);
                          });
                }
        return S3Link;
    }
    catch (error) {
        throw error;
      }
}

export default ReactS3;