import React, { useEffect, useState } from "react";
import {Button,Col,Container, Modal,Row,Form,Spinner,} from "react-bootstrap";
import Pagination from "../Common/Pagination";
import * as Icon from "react-bootstrap-icons";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { getAllTemplate } from "../Common/TemplateListSlice";
import edit from "../../Assets/edit.svg";
import deletes from "../../Assets/delete.svg";

export const ManageTemplateTarget = () => {
  const dispatch = useDispatch();

  const templateListData = useSelector(
    (state) => state?.templates?.templateList?.data?.templates
  );

  const commonData = useSelector(state=> state?.commonData?.commonData)

  const userInfo = useSelector((state) => state?.loginData?.userData?.data);

  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDel, setShowDel] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [loader, setLoader] = useState(true);

  // for Today Date
  const currentDate = new Date();

  const year = currentDate.getFullYear().toString().slice(-2);
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  const day = currentDate.getDate().toString().padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;

  // States for Pagination ----------
  const [pageNo, setPageNo] = useState(1);
  const [docPerPage, setDocPerPage] = useState(10);
  const [noOfPages, setNoOfPages] = useState();

  // List
  const [tempTargetList, setTempTargetList] = useState([]);
  const [templateNameList, setTemplateList] = useState([]);

  // States of Add Role ----------------
  const [id, setId] = useState();
  const [deptName, setDeptName] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [templateMessg, setTemplateMessg] = useState("");
  const [targetCount, setTargetCount] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [templateId, setTemplateId] = useState("");

  // States of Add User Error ----------------
  const [deptNameErr, setDeptNameErr] = useState(false);
  const [templateNameErr, setTemplateNameErr] = useState(false);
  const [templateMessgErr, setTemplateMessgErr] = useState(false);
  const [targetCountErr, setTargetCountErr] = useState(false);
  const [fromDateErr, setFromDateErr] = useState(false);
  const [toDateErr, setToDateErr] = useState(false);

  // States of Delete user -----------------------
  const [delId, setDelId] = useState();
  // state of Filter Data ---------------------------
  const [filterDepartment,setFilterDepartment] = useState()
  const [filterTemplate,setFilterTemplete] = useState()
  const [filterStartDate,setFilterStartDate] = useState()
  const [filterEndDate,setFilterEndDate] = useState()
  const [filterDataList1,setFilterDataList1] = useState()
  const [filterTemplateList1,setFilterTemplateList1] = useState()
  // const [filterToDate,setFilterToDate] = useState()

  useEffect(() => {
    getTemplateTarget();
    dispatch(getAllTemplate());
    dispatch(getAllTemplate());
    getFilterDatalist()
    getAllTemplateData()
    getFilterData()
  }, [pageNo, docPerPage]);

  const convertedData = templateListData !== null && templateListData !== undefined && templateListData.length > 0
                        ? templateListData?.map((entry) => ({
                                                label: entry?.templateTitle,
                                                value: entry?.templateId,
                        })) : "";

  const getAllTemplateData = async () => {
        await fetch(process.env.REACT_APP_BASE_URL + `/template?pageNo=${pageNo}&docPerPage=${docPerPage}`,
        {
          method: "GET",
        })
        .then((res) => res.json())
        .then((response) => {
          setTemplateList(response?.data?.templates);
          setNoOfPages(response.data.noOfPages);
          setLoader(false);
       })
        .catch((err) => {
          console.log("Error while getting users", err);
          setLoader(false);
      });
  };

  const getTemplateTarget = async () => {
    await fetch(process.env.REACT_APP_BASE_URL + "/templateTarget", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((response) => {
        setTempTargetList(response?.data?.templateTargets);
        setNoOfPages(response.data.noOfPages);
        setLoader(false);
      })
      .catch((err) => {
        console.log("Error while getting users", err);
        setLoader(false);
      });
  };
  const getFilterDatalist = async()=>{
    setLoader(true)
    await fetch(process.env.REACT_APP_BASE_URL + '/templateTarget/filterLabels',{
      method:"GET"
    }).then((res)=>res.json()).then((response)=>{
      setFilterDataList1(response?.data?.departmentOptions)
      setFilterTemplateList1(response?.data?.templpateTargetOptions)
                   setLoader(false)

    }).catch((err)=>{
            console.log("Error while getting users", err);
             setLoader(false)})
  }
  
  const getFilterData = async () => {
    await fetch(process.env.REACT_APP_BASE_URL + "/templateTarget/filter?pageNo=${pageNo}&docPerPage=${docPerPage}", {
      method: "POST",
      headers:{'Content-Type':'application/json'},
            body:JSON.stringify({
              departmentId:filterDepartment?.value,
              templateId:filterTemplate?.value,
            })
    })
      .then((res) => res.json())
      .then((response) => {
        setTempTargetList(response?.data?.templateTargets);
        closeFilterModal()
        setNoOfPages(response.data.noOfPages);
        setLoader(false);
      })
      .catch((err) => {
        console.log("Error while getting users", err);
        setLoader(false);
      });
  }

  const clearFilterData = ()=>{
    setFilterDepartment("")
    setFilterTemplete("")
  }
  const templateNames = {};

  const handleTemplateList = (selected) => {
    setTemplateName(selected);
    if (selected) {
      setTemplateName(selected);
      setTemplateNameErr(false)
    } else {
      setTemplateName(null);
      setTemplateNameErr(true)
    }
  };

  const handleDepartmentList = (selected) => {
    if (selected) {
      setDeptName(selected);
      setDeptNameErr(false)
    } else {
      setDeptName(null);
      setDeptNameErr(true)
    }

  };
  const filterDepartmentList = (selected) => {
    setFilterDepartment(selected);
  };
  const filterTemplateNameList = (selected) => {
    setFilterTemplete(selected);
    
  };

  const handleSelectChange = (selectedOption) => {
    setTemplateName((prevState) => ({
      ...prevState,
      selectedOption,
    }));
  };
  const handleSelectDepartment = (selectedOption) => {
    setDeptName((prevState) => ({
      ...prevState,
      selectedOption,
    }));
  };

  const closeAddModal = () => {
    setDeptName("");
    setTemplateName("");
    setTemplateMessg("");
    setTargetCount("");
    setFromDate("");
    setToDate("");

    setDeptNameErr(false);
    setTemplateNameErr(false);
    setTemplateMessgErr(false);
    setTargetCountErr(false);
    setFromDateErr(false);
    setToDateErr(false);

    setShowAdd(false);
    setShowEdit(false);
  };

  const closeFilterModal = ()=>{
    setShowFilter(false)
  }

  const addValidate = () => {
    let isValid = true;
    if (deptName.length === 0) {
      isValid = false;
      setDeptNameErr(true);
    } else if (templateName.length === 0) {
      isValid = false;
      setTemplateNameErr(true);
    } else if (templateMessg.length === 0) {
      isValid = false;
      templateMessgErr(true);
    } else if (targetCount.length === 0) {
      isValid = false;
      setTargetCountErr(true);
    } else if (fromDate.length === 0) {
      isValid = false;
      setFromDateErr(true);
    } else if (toDate.length === 0) {
      isValid = false;
      setToDateErr(true);
    }

    return isValid;
  };

  const addTemplateTarget = async () => {
    // setLoader(true);

    if (addValidate()) {
      await fetch(process.env.REACT_APP_BASE_URL + "/templateTarget/create", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          templateName: templateName.label,
          templateTitle: "title",
          templateMessage: templateMessg,
          templateId: templateName.value,
          targetCount: targetCount,
          toDate: toDate,
          fromDate: fromDate,
          image: "",
          variable: [],
          createdBy: userInfo.name,
          createdDate: formattedDate,
          departmentId: deptName.value,
          departmentName: deptName.label,
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.message === "Record Created Successfully...!") {
            toast.success("Role added successfully");
            setLoader(false);
            closeAddModal();
            getTemplateTarget();
          }
        })
        .catch((err) => {
          console.log("Err while adding user", err);
          setLoader(false);
        });
    } else {
      setLoader(false);
    }
  };

  /* ----- Edit Modal --------*/
  const setEditData = (itm) => {
    setTemplateId(itm.templateTargetId);
    setId(itm?._id);
    setTemplateName(itm?.templateName);
    setTemplateMessg(itm?.templateMessage);
    setTargetCount(itm?.targetCount);
    setToDate(new Date(itm?.toDate).toISOString().split("T")[0]);
    const selectedTemplate = convertedData !== undefined && convertedData !==null && convertedData.length > 0 ?
     convertedData?.find(
      (option) => option.label === itm?.templateName
    ) :[]
    setTemplateName(selectedTemplate || null);
    const selectedDepartment = commonData?.departments !== undefined && commonData?.departments !== null &&
                              commonData?.departments.length > 0 ?commonData?.departments?.find(
                              (option) => option.label === itm?.departmentName):[]
    setDeptName(selectedDepartment || null);
    setFromDate(new Date(itm?.fromDate).toISOString().split("T")[0]);
  };

  const editDAta = async () => {
    if (addValidate()) {
      await fetch(process.env.REACT_APP_BASE_URL + "/templateTarget/update", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          templateTargetId: templateId,
          departmentId: deptName.value,
          departmentName: deptName.label,
          templateId: templateName.value,
          templateName: templateName.label,
          templateMessage: templateMessg,
          targetCount: targetCount,
          fromDate: fromDate,
          toDate: toDate, 
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.message === "Record Updated Successfully...!") {
            toast.success("Record update successfully");
            setLoader(false);
            closeAddModal();
            getTemplateTarget();
          }
        })
        .catch((err) => {
          console.log("Err while adding user", err);
          setLoader(false);
        });
    } else {
      setLoader(false);
    }
  };

  const deleteDepart = async () => {
    setLoader(true);
    await fetch(
      process.env.REACT_APP_BASE_URL + `/templateTarget/delete/${delId}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.message === "Record Delete Successfully...!") {
          toast.success("User deleted successfully !");
          setShowDel(false);
          getTemplateTarget();
        }
        setLoader(false);
      })
      .catch((err) => {
        console.log("Err while adding user", err);
        setLoader(false);
      });
  };

  // function for pagination
  const handlePageChange = (pageNumber) => {
      setPageNo(pageNumber);
  };

  const handleDocsPerPage = (docsPageProp) => {
      setPageNo(1)
      setDocPerPage(docsPageProp);
  }

  return (
    <div className="ManageUsers outletPadding">
      <ToastContainer />
      <Container>
        <Row>
          <Row className="justify-content-between align-items-center mb-2">
            <Col md={5} className="d-flex mb-4 mb-md-0">
              <Button className="primaryBtn me-3" onClick={()=>setShowFilter(true)}><Icon.Funnel className="me-2" />filter</Button>
            </Col>
            <Col md={2} className="d-flex justify-content-end">
              <Button className="primaryBtn" onClick={() => setShowAdd(true)}>
                <Icon.PersonAdd className="me-2" />
                Add Template
              </Button>
            </Col>
          </Row>
          <div className="outer-wrapper mx-auto">
            {loader ? (
              <center>
                <Spinner variant="primary" className="mt-3" />
              </center>
            ) : (
              <div className="table-wrapper">
                <table>
                  <thead>
                    <th>Sr No</th>
                    <th>Template Name</th>
                    <th>Department Name</th>
                    <th>Target Count</th>
                    <th>Message</th>
                    <th>From Date</th>
                    <th>To Date</th>
                    <th>Actions</th>
                  </thead>
                  {tempTargetList !== null &&
                  tempTargetList !== undefined &&
                  tempTargetList.length > 0
                    ? tempTargetList?.map((itm, index) => {
                        const {departmentName, templateMessage, templateName, templateTitle, fromDate, toDate, targetCount} = itm;

                        return (
                          <tr key={Math.random() * 999999999}>
                            <td>
                              {pageNo !== 1 ? (
                                <> {index + 1 + docPerPage * (pageNo - 1)}</>
                              ) : (
                                <>{index + 1}</>
                              )}
                            </td>
                            <td>{templateName}</td>
                            <td>{departmentName}</td>
                            <td>{targetCount}</td>
                            <td>{templateMessage}</td>
                            <td>{new Date(fromDate).toLocaleDateString('en-GB')}</td>
                            <td>{new Date(toDate).toLocaleDateString('en-GB')}</td>
                            <td>
                                <img src={edit} style={{ cursor:'pointer' }} className="me-3 icon" 
                                  onClick={() => {setShowEdit(true); setEditData(itm);}}/>
                                <img src={deletes} style={{ cursor:'pointer' }} className="me-2 icon" 
                                  onClick={() => {setShowDel(true); setDelId(itm?._id);}}/>
                            </td>
                          </tr>
                        );
                      })
                    : "No data Found"}
                </table>
              </div>
            )}
          </div>
          <Pagination currentPage={pageNo} totalPages={noOfPages}
            onPageChange={handlePageChange} onDocsPerPage={handleDocsPerPage} docPerPage={docPerPage}/>
        </Row>

        {/* ---------------- Add User Modal ----------------------------------------------------------- */}
        <Modal
          size="lg"
          show={showAdd}
          backdrop="static"
          keyboard={false}
          centered
          onHide={closeAddModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h4 className="mb-0">
              <Icon.PersonAdd size={20} className="me-2" />
                Add Template Target
              </h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                <Form.Label>Department name</Form.Label>
                <Select
                  name="deptName"
                  value={deptName}
                  options={commonData?.departments}
                  onChange={(option) => {
                    handleDepartmentList(option);
                  }}/>
                  {deptNameErr ? (<p className="errMsg">Please enter department error</p>) : ("")}
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Template name</Form.Label>
                  <Select
                    name="templateName"
                    value={templateName}
                    options={convertedData}
                    onChange={(option) => {
                      handleTemplateList(option);
                    }}
                  />
                  {templateNameErr ? (<p className="errMsg">Please enter template name</p>) : ("")}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Template Message</Form.Label>
                  <Form.Control
                    placeholder="Enter template message"
                    name="templateMessg"
                    value={templateMessg}
                    onChange={(e) => {
                      if (
                        !e.target.value.match(
                          /[0-9+@#$&%!~=^_:(){}\[\]|/*./\//\-/?<>,;`'""/]/
                        ) &&
                        e.target.value.trim()
                      ) {
                        setTemplateMessg(e.target.value);
                      } else if (e.target.value.length === 0) {
                        setTemplateMessg(e.target.value);
                      }
                      e.target.value.length > 0
                        ? setTemplateMessgErr(false)
                        : setTemplateMessgErr(true);
                    }}
                  />
                  {templateMessgErr ? (
                    <p className="errMsg">Please enter template message</p>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Target Count</Form.Label>
                  <Form.Control
                    placeholder="Enter target count"
                    name="targetCount"
                    value={targetCount}
                    onChange={(e) => {
                      if (
                        !e.target.value.match(
                          /[A-Za-z+@#$&%!~=*./\s/g/\-/?<>,"/]/
                        ) &&
                        e.target.value.length <= 10
                      ) {
                        setTargetCount(e.target.value);
                      } else if (e.target.value.length === 0) {
                        setTargetCount(e.target.value);
                      }
                      e.target.value.length > 0
                        ? setTargetCountErr(false)
                        : setTargetCountErr(true);
                    }}
                  />
                  {targetCountErr ? (
                    <p className="errMsg">Please enter target count</p>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Form Date</Form.Label>
                  <Form.Control
                    className=""
                    type="date"
                    name="fromDate"
                    value={fromDate}
                    max={toDate}
                    min={new Date().toISOString().split("T")[0]}
                    onKeyDown={(event) => event.preventDefault()}
                    onChange={(e) => {
                      setFromDate(e.target.value);
                      fromDate?.length < 0
                        ? setFromDateErr(true)
                        : setFromDateErr(false);
                    }}
                  />
                  {fromDateErr ? (
                    <p className="errMsg">Please select from date</p>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>To Date</Form.Label>
                  <Form.Control
                    className=""
                    type="date"
                    name="toDate"
                    value={toDate}
                    // min={new Date().toISOString().split("T")[0]}
                    onKeyDown={(event) => event.preventDefault()}
                    onChange={(e) => {
                      setToDate(e.target.value);
                      toDate?.length < 0
                        ? setToDateErr(true)
                        : setToDateErr(false);
                    }}
                    min={fromDate}
                  />
                  {toDateErr ? (
                    <p className="errMsg">Please select to date</p>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>

          <Modal.Footer>
            <Button
              className="primaryBtn"
              onClick={addTemplateTarget}
              disabled={loader}
            >
              {loader ? (
                <Spinner size="sm" />
              ) : (
                <Icon.PersonAdd className="me-2" />
              )}
              Add
            </Button>
          </Modal.Footer>
        </Modal>

        {/* ---------------- Edit User Modal ----------------------------------------------------------- */}
        <Modal
          size="md"
          show={showEdit}
          backdrop="static"
          keyboard={false}
          centered
          onHide={closeAddModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {" "}
              <h4 className="mb-0">
                <Icon.PencilSquare size={20} className="me-2" />
                Edit Role
              </h4>{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Department name</Form.Label>
                  <Select
                    // isMulti={false}
                    // isSearchable={true}
                    name="deptName"
                    value={deptName}
                    options={commonData?.departments}
                    onChange={(option) => {
                      handleDepartmentList(option);
                    }}
                  />
                  {deptNameErr ? (
                    <p className="errMsg">Please enter department error</p>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Template name</Form.Label>
                  <Select
                    // isMulti={false}
                    // isSearchable={true}
                    name="templateName"
                    value={templateName}
                    options={convertedData}
                    onChange={(option) => {
                      handleTemplateList(option);
                    }}
                  />
                  {templateNameErr ? (
                    <p className="errMsg">Please enter template name</p>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Template Message</Form.Label>
                  <Form.Control
                    placeholder="Enter template message"
                    name="templateMessg"
                    value={templateMessg}
                    onChange={(e) => {
                      if (
                        !e.target.value.match(
                          /[0-9+@#$&%!~=^_:(){}\[\]|/*./\//\-/?<>,;`'""/]/
                        ) &&
                        e.target.value.trim()
                      ) {
                        setTemplateMessg(e.target.value);
                      } else if (e.target.value.length === 0) {
                        setTemplateMessg(e.target.value);
                      }
                      e.target.value.length > 0
                        ? setTemplateMessgErr(false)
                        : setTemplateMessgErr(true);
                    }}
                  />
                  {templateMessgErr ? (
                    <p className="errMsg">Please enter template message</p>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Target Count</Form.Label>
                  <Form.Control
                    placeholder="Enter target count"
                    name="targetCount"
                    value={targetCount}
                    onChange={(e) => {
                      if (
                        !e.target.value.match(
                          /[A-Za-z+@#$&%!~=*./\s/g/\-/?<>,"/]/
                        ) &&
                        e.target.value.length <= 10
                      ) {
                        setTargetCount(e.target.value);
                      } else if (e.target.value.length === 0) {
                        setTargetCount(e.target.value);
                      }
                      e.target.value.length > 0
                        ? setTargetCountErr(false)
                        : setTargetCountErr(true);
                    }}
                  />
                  {targetCountErr ? (
                    <p className="errMsg">Please enter target count</p>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Form Date</Form.Label>
                  <Form.Control
                    className=""
                    type="date"
                    name="fromDate"
                    value={fromDate}
                    min={new Date().toISOString().split("T")[0]}
                    onKeyDown={(event) => event.preventDefault()}
                    onChange={(e) => {
                      setFromDate(e.target.value);
                      fromDate?.length < 0
                        ? setFromDateErr(true)
                        : setFromDateErr(false);
                    }}
                  />
                  {fromDateErr ? (
                    <p className="errMsg">Please select from date</p>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>To Date</Form.Label>
                  <Form.Control
                    className=""
                    type="date"
                    name="toDate"
                    value={toDate}
                    min={new Date().toISOString().split("T")[0]}
                    onKeyDown={(event) => event.preventDefault()}
                    onChange={(e) => {
                      setToDate(e.target.value);
                      toDate?.length < 0
                        ? setToDateErr(true)
                        : setToDateErr(false);
                    }}
                  />
                  {toDateErr ? (
                    <p className="errMsg">Please select to date</p>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>

          <Modal.Footer>
            <Button className="primaryBtn" onClick={editDAta} disabled={loader}>
              {loader ? (
                <Spinner size="sm" />
              ) : (
                <Icon.PersonAdd className="me-2" />
              )}
              Edit User
            </Button>
          </Modal.Footer>
        </Modal>

        {/* ---------------- Delete User Modal ----------------------------------------------------------- */}
        <Modal
          size="md"
          show={showDel}
          backdrop="static"
          keyboard={false}
          centered
          onHide={() => setShowDel(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {" "}
              <h4 className="mb-0">
                <Icon.Trash size={20} className="me-2" />
                Delete User
              </h4>{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4 className="text-center mb-0">Are you sure to delete ?</h4>
          </Modal.Body>

          <Modal.Footer>
            <Button
              className="primaryBtn"
              disabled={loader}
              onClick={deleteDepart}
            >
              {loader ? <Spinner size="sm" /> : <Icon.Trash className="me-1" />}
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        {/* ---------------- Filter data Modal ----------------------------------------------------------- */}
        <Modal
          size="md"
          show={showFilter}
          backdrop="static"
          keyboard={false}
          centered
          onHide={closeFilterModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h4 className="mb-0">
              <Icon.PencilSquare size={20} className="me-2" />
              Filter Role
              </h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                <Form.Label>Department name</Form.Label>
                <Select
                  isSearchable={true}
                  name="deptName"
                  value={filterDepartment}
                  options={filterDataList1}
                  onChange={(option) => {
                    filterDepartmentList(option);
                  }}/>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="mb-3">
                <Form.Label>Template name</Form.Label>
                <Select
                  isSearchable={true}
                  name="templateName"
                  value={filterTemplate}
                  options={filterTemplateList1}
                  onChange={(option) => {
                    filterTemplateNameList(option);
                  }} />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                <Form.Label>Form Date</Form.Label>
                <Form.Control
                  className=""
                  type="date"
                  name="fromDate"
                  value={filterStartDate}
                  min={new Date().toISOString().split("T")[0]}
                  onKeyDown={(event) => event.preventDefault()}
                  onChange={(e) => {
                    setFilterStartDate(e.target.value);
                  }}/>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="mb-3">
                <Form.Label>To Date</Form.Label>
                <Form.Control
                  className=""
                  type="date"
                  name="toDate"
                  value={toDate}
                  min={fromDate}
                  onKeyDown={(event) => event.preventDefault()}
                  onChange={(e) => {
                    setFilterEndDate(e.target.value);
                  }}/>
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>

          <Modal.Footer>
              {loader ? (<Spinner size="sm" />) : 
              <Button className="primaryBtn" onClick={clearFilterData} disabled={loader}>Clear </Button> }
              {loader ? (
                <Spinner size="sm" />
              ) : (
                <Button className="primaryBtn" onClick={getFilterData} disabled={loader}>Filter</Button>
              )}
          </Modal.Footer>
        </Modal>
      </Container>
    </div>
  );
};
