import React, { createContext, useEffect, useState } from 'react'

export const S3Context = createContext(null)

const S3Provider = ({children}) => {
    const [s3Data, setS3Data] = useState()

    useEffect(()=>{
        getAwsCredentails()
    },[])

    const getAwsCredentails = async()=>{
        await fetch(process.env.REACT_APP_BASE_URL + '/aws/get/IT_GOA', {
          method:'GET'
        }).then((res)=>res.json()).then((response)=>{
          setS3Data(response?.data);
        }).catch((err)=>{
          console.log("err while getting aws credentials", err);
        })
    }

  return (
    <S3Context.Provider value={{
            s3Data,
            setS3Data
    }}>
      {children}
    </S3Context.Provider>
  )
}

export default S3Provider
