import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import * as Icon from 'react-bootstrap-icons';
import { Button, Spinner, Accordion, OverlayTrigger, Popover, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setLogout } from '../Auth/LoginSlice';
import { setActiveKey, setMultiMsg } from './ActiveKeySlice';
import { setCommonData } from '../Admin/commonSlice';



const Menubar = () => {

  const userState = useSelector(state => state.loginData.userData);
  const activeKeyRedux = useSelector(state => state.activeData.activeNumberKey);

  function setnav() {
    const mobilenav = document.querySelector(".mobile-nav");
    const toggle = document.querySelector(".mobile-nav-toggle");

    const visibility = mobilenav.getAttribute('data-visible');
    if (visibility === "false") {
      mobilenav.setAttribute("data-visible", true);
      toggle.setAttribute("aria-expanded", true);  
      document.querySelector("body").style.overflow = 'hidden';
    }
    else if (visibility === "true") {
      mobilenav.setAttribute("data-visible", false);
      toggle.setAttribute("aria-expanded", false);
      document.querySelector("body").style.overflow = 'auto';
    }
  }


  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleLogout = () => {
    setLoader(true);
    dispatch(setLogout(null));
    dispatch(setActiveKey(0));
    dispatch(setCommonData(null));
    dispatch(setMultiMsg(false));
    navigate('/');
    setLoader(false);
  }

  const entering = (e) => {
    e.children[0].style.borderTopColor = 'white';
    e.children[1].style.backgroundColor = 'white';
    e.children[0].style.borderRadius = '12px';
    e.children[1].style.borderRadius = '12px';
  };

  const handleAccordionChange = (activeKey, subActiveKey, tabName) => {
    dispatch(setActiveKey({ activeKey: activeKey, subActiveKey:subActiveKey, activeTab: tabName }));
  };

  // const Popup = (
  //   <Popover>
  //     <Popover.Body>
  //       <div className='PopupData'>
  //         <div>
  //           <Icon.Person className='me-1' size={18} /><p><strong>Name - </strong>{userState?.firstName + " " + userState?.lastName}</p>
  //         </div>
          
  //         <div>
  //           <Icon.Briefcase className='me-2' size={14} /><p><strong>Role - </strong>{userState.designation}</p>
  //         </div>
  //         <div>
  //           <Icon.Award className='me-2' size={15} /><p><strong>Dept. - </strong>{userState.department[0].label}</p>
  //         </div>
  //         <div>
  //           <Icon.Mortarboard className='me-2' size={15} /><p><strong>Exp. - </strong>{userState.experience}</p>
  //         </div>
  //         <div>
  //           <Icon.Envelope className='me-2' size={15} /><p><strong>Email - </strong>{userState.workEmail}</p>
  //         </div>
  //         <div>
  //           {userState.isApproved ? <Icon.ToggleOn className='me-2' size={15} /> : <Icon.ToggleOff className='me-2' size={15} />}
  //           <p><strong>Status - </strong>{userState.isApproved ? "Approved" : "Pending"}</p>
  //         </div>
  //       </div>
  //     </Popover.Body>
  //   </Popover>
  // )

  return (

    <nav>
      <Link className='d-flex align-items-center'>
          <h5 className='mb-0 ms-2'>{ activeKeyRedux.activeTab }</h5> 
      </Link>

     <div className='d-flex align-items-center'>
     <Icon.PersonFill className='me-2 d-block d-lg-none' size={35} style={{cursor:'pointer'}} 
      onClick={()=> {navigate(`/dashboard/profile`); dispatch(setActiveKey({activeKey:activeKeyRedux.activeKey,activeTab:'Profile'})); }}/>
      <div className="mobile-nav-toggle" aria-controls='selectNav' aria-expanded="false" onClick={setnav}>
        <div className='menu-btn__burger'></div>
      </div>
      </div>


      <ul className="lg-nav">
        {/* <OverlayTrigger key='bottom' placement='bottom' overlay={Popup} onEntering={entering}> */}
          <div className='d-flex' style={{ border: 'none', cursor:'pointer' }} onClick={()=> {navigate(`/dashboard/profile`); dispatch(setActiveKey({activeKey:activeKeyRedux.activeKey,activeTab:'Profile'}));}}>
            <Icon.PersonFill className='me-2' size={35} />
            <div>
              <p style={{ fontWeight: '600', fontSize: '15px', marginBottom: '-4px' }}>{userState?.data?.name}</p>
              <p style={{ fontSize: '12px', marginBottom: '0px' }}>{userState?.data?.userType}</p>
            </div>
          </div>
        {/* </OverlayTrigger> */}

        {/* <Button className='btn btn-logout ms-3' onClick={handleLogout}>{ loader ? <Spinner animation="border" variant="light" className='spinner'/> :<><Icon.BoxArrowLeft className='me-2'/>Logout</>}</Button> */}
      </ul>

      <ul id="selectNav" className="mobile-nav" data-visible="false">

        <div className="d-flex justify-content-between flex-column h-100" >
       
        {
            userState?.data?.userType === "Admin" || userState?.data?.userTypeKey === "DH" ?
      
              <Accordion flush defaultActiveKey={activeKeyRedux?.activeKey}>

                <Accordion.Item eventKey={1} onClick={() => { handleAccordionChange(1, 1, "Dashboard"); navigate("/dashboard"); }} className={activeKeyRedux?.activeKey === 1 ? "activeMenu" : ""}>
                  <Accordion.Header >{activeKeyRedux?.activeKey === 1 ? <Icon.HouseDoorFill className='me-2' /> : <Icon.HouseDoor className='me-2 icon' />}Dashboard</Accordion.Header>
                </Accordion.Item>

               

                <Accordion.Item eventKey={2} onClick={() => { handleAccordionChange(2, 2, "Manage Consumer"); navigate("manage-consumer"); }} className={activeKeyRedux?.activeKey === 2 ? "activeMenu" : ""}>
                  <Accordion.Header >{activeKeyRedux?.activeKey === 2 ? <Icon.PersonBadgeFill className='me-2' /> : <Icon.PersonBadge className='me-2 icon' />}Consumer</Accordion.Header>
                </Accordion.Item>

                {
                  userState.data.userTypeKey === "DH" &&
                  <Accordion.Item eventKey={3} onClick={() => { handleAccordionChange(3, 3, "Manage Users"); navigate("manage-users"); }} className={activeKeyRedux?.activeKey === 3 ? "activeMenu" : ""}>
                  <Accordion.Header >{activeKeyRedux?.activeKey === 3 ? <Icon.PeopleFill className='me-2' /> : <Icon.People className='me-2 icon' />}Manage Users</Accordion.Header>
                </Accordion.Item> 

                }
 
                
                {
                      userState.data.userTypeKey === "admin" && 
            
                <Accordion.Item eventKey={5} className={activeKeyRedux?.activeKey === 5 ? "activeMenu dropDown" : "dropDown"}>
                      <Accordion.Header onClick={() => { handleAccordionChange(5, activeKeyRedux.subActiveKey, activeKeyRedux.activeTab); setnav();}}>
                        {activeKeyRedux?.activeKey === 5 ? <Icon.EnvelopeAtFill className='me-2' /> : <Icon.EnvelopeAt className='me-2 icon' />}Departments</Accordion.Header>

                <Accordion.Body className="d-flex flex-column">
                  <Button onClick={() => { handleAccordionChange(5, 5.1, "Manage Users"); navigate("manage-users"); setnav();}} className={activeKeyRedux?.subActiveKey===5.1 ? "accordionNavActive mb-3" : "accordionNav mb-3"}>
                    {activeKeyRedux?.subActiveKey === 5.1 ? <Icon.PeopleFill className='me-2' /> : <Icon.People className='me-2 icon' />}Manage Users
                  </Button>        
                  <Button  onClick={() => { handleAccordionChange(5, 5.2, "Manage Category"); navigate("manage-category"); setnav();}} className={activeKeyRedux?.subActiveKey===5.2 ? "accordionNavActive mb-3" : "accordionNav mb-3"}>
                   {activeKeyRedux?.subActiveKey === 5.2 ? <Icon.PersonBoundingBox className='me-2' /> : <Icon.PersonBoundingBox className='me-2 icon' />}Manage User Category
                  </Button>
                  <Button onClick={() => { navigate("manage-department"); handleAccordionChange(5, 5.3, "Manage Departments"); setnav();}} className={activeKeyRedux?.subActiveKey===5.3 ? "accordionNavActive mb-3" : "accordionNav mb-3"}>
                    {activeKeyRedux?.subActiveKey === 5.3 ? <Icon.BuildingFillGear className="me-2" /> : <Icon.BuildingGear className="me-2" />}Manage Departments
                  </Button>
                  <Button onClick={() => { navigate("manage-department-credit"); handleAccordionChange(5, 5.4, "Manage Department Credit"); setnav(); }} className={activeKeyRedux?.subActiveKey===5.4 ? "accordionNavActive mb-3" : "accordionNav mb-3"}>
                      {activeKeyRedux?.subActiveKey === 5.4 ? <Icon.CreditCard2FrontFill className="me-2" /> : <Icon.CreditCard2Front className="me-2" />}Department Credit
                  </Button>
                </Accordion.Body>
            </Accordion.Item> 
            }
                 
               <Accordion.Item eventKey={6} className={activeKeyRedux?.activeKey === 6 ? "activeMenu dropDown" : "dropDown"}>
                  <Accordion.Header onClick={() => { handleAccordionChange(6, activeKeyRedux.subActiveKey, activeKeyRedux.activeTab); setnav();}}>
                    {activeKeyRedux?.activeKey === 6 ? <Icon.EnvelopeAtFill className='me-2' /> : <Icon.EnvelopeAt className='me-2 icon' />}Templates</Accordion.Header>

                  <Accordion.Body className="d-flex flex-column">
                  <Button onClick={() => { navigate("manage-templates"); handleAccordionChange(6, 6.1, "Manage Templates"); setnav(); }} className={activeKeyRedux?.subActiveKey===6.1 ? "accordionNavActive mb-3" : "accordionNav mb-3"}>
                    {activeKeyRedux?.subActiveKey === 6.1 ? <Icon.EnvelopeAtFill className="me-2" /> : <Icon.EnvelopeAt className="me-2" />}Manage Templates
                    </Button>
                  </Accordion.Body>
                </Accordion.Item> 

                <Accordion.Item eventKey={7} onClick={() => { handleAccordionChange(7, 7, "Messenger"); navigate("messenger"); setnav();}} className={activeKeyRedux?.activeKey === 7 ? "activeMenu" : ""}>
                  <Accordion.Header >{activeKeyRedux?.activeKey === 7 ? <Icon.ChatDotsFill className='me-2' /> : <Icon.ChatDots className='me-2 icon' />}Messenger</Accordion.Header>
                </Accordion.Item>

              </Accordion> :''

          }

          <div className='px-2'>
            <Button className='LogoutBtn w-100' onClick={handleLogout}>{loader ? <Spinner animation="border" variant="light" className='spinner' /> : <><Icon.BoxArrowLeft className='me-2' />Logout</>}</Button>
          </div>

        </div>


      </ul>

    </nav>
  )
}

export default Menubar;