import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Container, Row, Spinner, Modal, Form } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import Pagination from '../Common/Pagination';
import edit from "../../Assets/edit.svg";
import deletes from "../../Assets/delete.svg";
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import AddUserModel from './Modal/AddUserModel';
import { useSelector } from 'react-redux';

const ViewDept = () => {
    const location = useLocation();
    const itm = location?.state;
    const navigate = useNavigate()

    const [showAdd, setShowAdd]  = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showDel, setShowDel] = useState(false);
    const [showFilter, setShowFilter] = useState(false)
    const [loader, setLoader] = useState(false);

    // States for form Select option ------
    const [users, setUsers] = useState();

    // States for Pagination ----------
    const [pageNo, setPageNo] = useState(1);
    const [docPerPage, setDocPerPage] = useState(10);
    const [noOfPages, setNoOfPages] = useState();

    const closeAddModel = () => {
        setShowAdd(false)
    }

      // function for pagination
    const handlePageChange = (pageNumber) => {
        setPageNo(pageNumber);
    };
  
    const handleDocsPerPage = (docsPageProp) => {
        setPageNo(1)
        setDocPerPage(docsPageProp);
    }
    useEffect(()=>{
        getAllUsers();
    },[])

    const getAllUsers = async()=>{
        setLoader(true);
        await fetch(process.env.REACT_APP_BASE_URL + `/user?pageNo=${pageNo}&docPerPage=${docPerPage}&departmentId=${itm?.departmentId}`,{
            method:'GET',
            headers:{'Content-Type':'application/json'}
        }).then((res)=>res.json()).then((response)=>{
            console.log("response at users", response);
            setUsers(response?.data?.users);
            setNoOfPages(response.data.noOfPages);
            setLoader(false);
        }).catch((err)=>{
            console.log("Error while getting users", err);
            setLoader(false);
        })
    }

  return (
    <div className='ViewDept outletPadding'>
        <ToastContainer/>
        {/* <AddUserModel show={showAdd} close={closeAddModel} getAllUsers = {getAllUsers} deptName={itm?.departmentName} deptId={itm?.departmentId}/> */}
        <Container>
        <Button className='primaryBtn mb-4' onClick={()=>navigate(-1)} style={{marginLeft:"5px"}}><span ><Icon.ArrowLeft className='me-2'/></span>Back</Button>
            <Row>
                <Card className='d-flex'>
                    <h5 className='text-center'>
                        <Icon.Postcard className='me-2'/>Department Details
                    </h5>
                    <Row>
                        <Col md={2}>
                        <h6><Icon.Person className='me-2'/>Name -</h6>
                        </Col>
                        <Col md={10}>
                            <p>{itm?.departmentName}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2}>
                        <h6><Icon.Envelope className='me-2'/>Email -</h6>
                        </Col>
                        <Col md={10}>
                            <p>{itm?.email}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2}>
                        <h6><Icon.Telephone className='me-2'/>Number -</h6>
                        </Col>
                        <Col md={10}>
                            <p>{itm?.whatsappNo}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2}>
                        <h6><Icon.GeoAlt className='me-2'/>Service Address -</h6>
                        </Col>
                        <Col md={10}>
                            <p>{itm?.service_address}</p>
                        </Col>
                    </Row>
                     {
                        itm?.socialMediaLinks.length>0 &&
                        <Row>
                            <Col md={2}>
                            <h6><Icon.Phone className='me-2'/>Social Link -</h6>
                            </Col>
                            <Col md={10}>
                                <p>{itm?.socialMediaLinks[0]}</p>
                            </Col>
                        </Row>
                    }
                    
                    <Row>
                        <Col md={2}>
                        <h6><Icon.CardList className='me-2'/>Description -</h6>
                        </Col>
                        <Col md={10}>
                            <p>{itm?.description}</p>
                        </Col>
                    </Row>
                </Card>
            </Row>


        {/* <h5 className='text-center mt-5'><Icon.Person className='me-2'/>Department User List</h5>
        <Row className='justify-content-end'>
            <Col md={3}>
                <Button className='primaryBtn ms-auto me-0 me-md-3' onClick={()=>setShowAdd(true)}>
                    <Icon.PersonAdd className='me-2'/>Add User
                </Button>
            </Col>
        </Row>
            <div className="outer-wrapper mx-auto">
                {
                  loader ? <center><Spinner variant='primary' className='mt-3'/></center> :
                    <div className="table-wrapper">
                        <table>
                        <thead>
                                <th>Sr No</th>
                                <th>Profile</th>
                                <th>Name</th>
                                <th>Mobile No</th>
                                <th>Whatsapp No</th>
                                <th>Email</th>
                                <th>Department</th>
                                <th>Designation</th>
                                <th>Category</th>
                                <th>Role</th>
                                <th>Actions</th>
                        </thead>
                            {
                                users!== null && users !== undefined && users.length > 0 ? users.map((itm, index) => {

                                    const { profileImg,name,contactNo,whatsappNo, email, departmentName, designation, categoryName, userType} = itm

                                        return (
                                            <tr key={Math.random() * 999999999}>
                                                <td>{pageNo !== 1 ? (<> {index + 1 + docPerPage * (pageNo - 1)}</>) : <>{index + 1}</>}</td>
                                                <td><img src={profileImg} alt='profile' width={40}/></td>
                                                <td>{name}</td>
                                                <td>{contactNo}</td>
                                                <td>{whatsappNo}</td>
                                                <td>{email}</td>
                                                <td>{departmentName}</td>
                                                <td>{designation}</td>
                                                <td>{categoryName}</td>
                                                <td>{userType}</td>
                                                <td>
                                                <img src={edit} className='me-3 icon' style={{ cursor:'pointer' }}/>
                                                <img src={deletes} className='me-2 icon' style={{ cursor:'pointer' }}/>
                                                </td>
                                            </tr>
                                        )
                                  })
                                  : 'No data Found'
                          }
                      </table>
                  </div>
                }
                  
            </div>
              <Pagination currentPage={pageNo} totalPages={noOfPages}
                  onPageChange={handlePageChange} onDocsPerPage={handleDocsPerPage} docPerPage={docPerPage}/> */}
        </Container>

   
    </div>
  )
}

export default ViewDept;