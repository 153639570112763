import { Container, Button, Form } from "react-bootstrap";
import * as Icon from 'react-bootstrap-icons';

function Pagination({ currentPage, totalPages, onPageChange, onDocsPerPage, docPerPage }) {

  // Logic for rendering pagination buttons
  const pageNumbers = []; // Array to store individual page numbers

  // Generate page numbers based on the total number of pages

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="pagination mt-3">
      <Container className="d-flex justify-content-end w-100">
        <div className="d-flex flex-row flex-md-row" >
          <div className='d-flex mb-4 mb-md-0' style={{ width: 'maxContent' }}>

            {/* Render previous page button */}
            <Button className='prev' disabled={currentPage === 1} onClick={() => onPageChange(currentPage - 1)}>
              <Icon.ChevronLeft className='me-1' />Prev
            </Button>

            {/* Render individual page numbers */}
            {pageNumbers.map((pageNumber) => (
              <Button
                key={pageNumber}
                className={pageNumber === currentPage ? 'activePageButton' : 'pageButton'}
                onClick={() => onPageChange(pageNumber)} >
                {pageNumber}
              </Button>
            ))}


            {/* Render next page button */}
            <Button className='next' disabled={currentPage === totalPages} onClick={() => onPageChange(currentPage + 1)} >
              Next<Icon.ChevronRight className="ms-1" />
            </Button>
          </div>

          <div>
            <Form.Select value={docPerPage} onChange={(e) => onDocsPerPage(e.target.value)}>
              <option value='10'>10/Page</option>
              <option value='30'>30/Page</option>
              <option value='60'>60/Page</option>
              <option value='100'>100/Page</option>
            </Form.Select>
          </div>
        </div>
      </Container>
    </div>

  );
}

export default Pagination;