import React,{ useEffect, useState } from 'react';
import { Container, Row, Col, Card,  Form, Button, InputGroup,Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import * as Icon from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import {getUserData, setLogout} from './LoginSlice';
import { setActiveKey, setMultiMsg } from '../Common/ActiveKeySlice';
import logo from '../../Assets/logo.svg'
import { setCommonData } from '../Admin/commonSlice';
import { setActiveChat, setOnlineUsers } from '../Messenger/ChatSlice';


const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userState = useSelector(state => state?.loginData?.userData);


// const loading = useSelector(state => state.loginData.loading);
// console.log("userState", userState);
//   if(userState===null){
//     navigate('/');
//     console.log("Cant Navigate");
//   }

useEffect(() => {
  if(userState){
  if(userState.message === "Invalid credentials."){
    NoUserFound();
    setLoader(false);
    dispatch(setLogout(null));
  }else if(userState.message === 'No account with this mobile has been registered.'){
            NoUserFound();
            setLoader(false);
            dispatch(setLogout(null));
  } else if( userState?.data?.userType ==="Admin" || userState?.data?.userType === "Department Head"){
 
    const getAllDropDowns= async()=>{
          await fetch(process.env.REACT_APP_BASE_URL +  "/user/dropdownValues",{
              method:'POST',
              headers:{'Content-Type':'application/json'},
              body:JSON.stringify({
                  userTypeKey: userState?.data?.userTypeKey==="DH" ? "DH" : "",
                  departmentId: userState?.data?.userTypeKey==="DH" ? userState?.data?.departmentId : ""
              })
          }).then(res=>res.json()).then((response)=>{
            dispatch(setCommonData(response?.data));
          }).catch((err)=>{
              console.log("Error while getting users", err);
          })
      } 
      getAllDropDowns();
        setLoader(false);
          navigate('/dashboard');
  }
  else{
    setLoader(false);
    toast.error('Something went wrong, please try again');
    dispatch(setLogout(null));
    dispatch(setActiveKey(1));
    dispatch(setActiveChat(''));
    dispatch(setOnlineUsers([]));
    dispatch(setCommonData(null));
    dispatch(setMultiMsg(false));
  }
}
}, [userState])

 
  const NoUserFound = () => toast.error("Invalid credentials");

 /// --- Data States -------
  const [id, setID] = useState('');
  const [pass, setPass] = useState('');

  // --- Err States ---------
  const [idErr, setIDErr] = useState(false);
  const [passErr, setPassErr] = useState(false);


  const [loader, setLoader] = useState(false);
  const [showPass, setShowPass] = useState(false);

  
  const validate = () => {
    let FormValid = true;

    if (!id || id.length<10) {
      FormValid = false;
      setIDErr(true);
    }
    else if(!pass){
    FormValid = false;
    setPassErr(true);
  }
    return FormValid;
  }

  const handlerSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    if (validate()) {
      dispatch(getUserData({ mobile:id, password:pass }));
      dispatch(setActiveKey({ activeKey:1, activeTab:'Home'}));
    } else {
      setLoader(false);
    }
}


return (

<div className='login'>
  <ToastContainer/>
      <Container className='h-100'>
        <Row className='h-100 justify-content-center align-items-center'>
          <Col md={5}>
          <Card>
            <div className='text-center' data-aos='fade-up'  data-aos-delay="100">
            <h2>Welcome to IT GOA</h2>
            <p className='subHeading'><Icon.BoxArrowInRight className="me-2"/>Login to your account</p>
            {/* <img src={logo} alt='Goa Government' width={200} height={100} /> */}
            </div>
        
          <Form onSubmit={handlerSubmit}>
            <Form.Group className="mb-3" data-aos="fade-up" data-aos-delay="100">
                <Form.Label>Mobile Number<span> *</span></Form.Label>
                <InputGroup>
                  <InputGroup.Text><Icon.Telephone size={20}/></InputGroup.Text>
                        <Form.Control name="ID" placeholder="Enter Mobile Number" value={id}
                          onChange={(e) => { if (!e.target.value.match(/[A-Za-z+@#$&%!~=*./\s/g/\-/?<>,"/]/) && e.target.value.length <= 10) {
                                setID(e.target.value);
                            } else if (e.target.value.length === 0) {
                              setID(e.target.value);
                            }
                            id.length >= 0 ? setIDErr(false) : setIDErr(true);
                          }} />
                      </InputGroup>
                        {idErr ? <p className='errMsg'> Please enter valid Mobile  Number </p> : null}
              </Form.Group>

              <Form.Group className="mb-4" data-aos="fade-up" data-aos-delay="200">
                      <Form.Label> Password<span> *</span>
                      </Form.Label>
                      <InputGroup>
                        <InputGroup.Text onClick={ () => setShowPass(!showPass) }>
                        { showPass ? <Icon.EyeSlash size={20} style={{ cursor:'pointer' }} onClick={ () => setShowPass(!showPass) } /> :
                          <Icon.Eye size={20} style={{ cursor:'pointer' }} /> }
                        </InputGroup.Text>

                        <Form.Control  type={showPass ? "text" : "password"}  placeholder="Enter password" name="pass"
                          value={pass} onChange={(e) => {
                            if ( !e.target.value.match(
                                /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{10,}$/g ) &&  e.target.value.trim()
                            ) {
                              setPass(e.target.value);
                            } else if (e.target.value.length === 0) {
                              setPass(e.target.value);
                            }
                            pass.length >= 0  ? setPassErr(false) : setPassErr(true);
                          }} />
                      </InputGroup>
                      {passErr ? <p className="errMsg">Password enter valid password</p> : null}
                    </Form.Group>
              
              <div data-aos="fade-up" data-aos-delay="300">
                {
                   loader ?  <center><Spinner variant="primary"/></center> : 
                   <Button type='submit' className='primaryBtn mx-auto'>
                      <Icon.BoxArrowInRight size={18} className='me-2'/> Log In
                   </Button>
                  }
                  {/* <p className='text-end mt-2' style={{fontSize:'12px', textDecoration:'underline', cursor:'pointer'}}
                      onClick={()=>navigate('/forgot-pasword')}>Forgot password ?</p> */}

            </div>
            </Form>
           
            </Card>
          </Col>

        
        </Row>
      </Container>
    </div>

  )
}

export default Login;