import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Modal, Row, Form, Spinner } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { ToastContainer, toast } from 'react-toastify';
import Select from "react-select";
import {useDispatch, useSelector} from "react-redux"
import { getUserData } from '../../Auth/LoginSlice';

export const AddTemplateModel = ({ show, close, reCallData }) => {
    const dispatch = useDispatch()
    const loginData = useSelector((state) => state?.loginData?.userData?.data?.name)

    const commonData = useSelector(state=> state?.commonData?.commonData)
    
    const [loader, setLoader] = useState(false);

    // States of Add Role ----------------
    const [id, setId] = useState();
    const [templateTitle, setTemplateTitle] = useState("");
    const [message, setMessage] = useState("")
    const [image, setImage] = useState("")
    const [variable, setVariable] = useState([])
    const [noOfVariables, setNoOfVariables] = useState("")
    const [createdBy, setCreatedBy] = useState("")
    const [createdDate, setCreatedDate] = useState("")
    const [departmentId, setDepartmentId] = useState("")
    const [departmentName, setDepartmentName] = useState("")
    const [channelEndDate, setChannelEndDate] = useState("")
    const [category, setCategory] = useState("")
    const [language, setLanguage] = useState("")
    const [variableValues, setVariableValues] = useState([]);
    const [variableErr, setVariableErr] = useState(Array(variableValues.length).fill(false));

    // States of Add User Error ----------------
    const [templateTitleErr, setTemplateTitleErr] = useState(false);
    const [messageErr, setMessageErr] = useState(false);
    const [imageErr, setImageErr] = useState(false);
    // const [variableErr, setVariableErr] = useState(false)
    const [noOfVariablesErr, setNoOfVariablesErr] = useState(false)
    const [createdDateErr, setCreatedDateErr] = useState(false)
    const [departmentNameErr, setDepartmentNameErr] = useState(false)
    // const [channelEndDateErr, setChannelEndDateErr] = useState(false)
    const [categoryErr, setCategoryErr] = useState(false)
    const [languageErr, setLanguageErr] = useState(false)

    const closeAddModal = () => {
        setTemplateTitle('');
        setMessage('')
        setImage('')
        setVariable('')
        setNoOfVariables('')
        setCreatedDate('')
        setDepartmentName('')
        // setChannelEndDate('')
        setCategory('')
        setLanguage('')

        setTemplateTitleErr(false);
        setMessageErr(false)
        setImageErr(false)
        setVariableErr(false)
        setNoOfVariablesErr(false)
        setCreatedDateErr(false)
        setDepartmentNameErr(false)
        // setChannelEndDateErr(false)
        setCategoryErr(false)
        setLanguageErr(false)

        close()
    }

    const addValidate = () => {
        let isValid = true;

        if (templateTitle.length === 0) {
            isValid = false;
            setTemplateTitleErr(true);
        }
        else if (departmentName.length === 0) {
            isValid = false;
            setDepartmentNameErr(true);
        }
        else if (category.length === 0) {
            isValid = false;
            setCategoryErr(true);
        }
        else if(language.length===0){
            isValid=false;;
            setLanguageErr(true);
        }
        else if (createdDate.length === 0) {
            isValid = false;
            setCreatedDateErr(true);
        }
        // else if (channelEndDate.length === 0) {
        //     isValid = false;
        //     setChannelEndDateErr(true);
        // }
        else if (language.length === 0) {
                isValid = false;
                setLanguageErr(true);
        }else if (message.length === 0) {
            isValid = false;
            setMessageErr(true);
        }else if (noOfVariables.length === 0) {
            isValid = false;
            setNoOfVariablesErr(true);
        }
        
           // Validate variables
            const variableFieldErrorState = Array(variableValues.length).fill(false);
            for (let i = 0; i < variableValues.length; i++) {
                if (!variableValues[i]) {
                    isValid = false;
                    variableFieldErrorState[i] = true;
                }
            }
            setVariableErr(variableFieldErrorState);

        // else if (!image) {
        //     isValid = false;
        //     setImageErr(true);
        // }

        return isValid;
    }

    const addTemplate = async () => {
        setLoader(true);
        setTemplateTitleErr(false);
        setMessageErr(false)
        // setImageErr(false)
        setVariableErr(false)
        setNoOfVariablesErr(false)
        setCreatedDateErr(false)
        setDepartmentNameErr(false)
        // setChannelEndDateErr(false)
        setCategoryErr(false)
        setLanguageErr(false)

        if (addValidate()) {
            const variablesArray = variableValues.map((item) => item.value); 

            await fetch(process.env.REACT_APP_BASE_URL +'/template/create', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    
                    templateTitle: templateTitle,
                    message: message,
                    variable: variablesArray,
                    noOfVariables:noOfVariables,
                    createdBy: loginData,
                    createdDate: createdDate,
                    departmentName: departmentName.label,
                    // channelEndDate: channelEndDate,
                    category: category.label,
                    language: language.value,
                    allowToCategoryChange: false
                })
            }).then((res) => res.json()).then((response) => {
                console.log("response", response);
                setLoader(false);
                if (response.message === "Record Created Successfully...!") {
                    toast.success("Template added successfully");
                    setLoader(false);
                    closeAddModal();
                    reCallData()
                    // getAllTemplateData();
                }else if(response.message === "Template title already exist...!"){
                    toast.error("Template title already exists.");
                    setLoader(false);
                }
            }).catch((err) => {
                console.log("Err while adding user", err);
                setLoader(false);
            })
        }
        else {
            setLoader(false);
        }
    }

    const categoryList = [
        // {value:"AUTHENTICATION", label:"AUTHENTICATION"},for now it is commented because problem in AUTHENTICATION
                            {value:"MARKETING", label:"MARKETING"},
                            {value:"UTILITY", label:"UTILITY"}]

    const handleCategoryList = (selected) =>{
        setCategory(selected)
    }

    const handleDepartment = (selected) =>{
        setDepartmentName(selected)
    }

    const langList = [{value : "en", label:"English(en)"}]

    const handleLanguageList = (selected) =>{
        setLanguage(selected)
    }

    const [listOfArray, setListOfArray] = useState([])
    const [messageArray, setMessageArray] = useState([])

    const handleMessageOnChange = (e) => {
        const value = e.target.value
        if (value.length <= 1024) {
            setMessage(value);
            const arrayOfWords = value.split(" ");
            setListOfArray(arrayOfWords);
            setMessageArray(arrayOfWords.map((word) => ({ value: word, label: word })));
          }
    }

    const handleVariableChange = (option, index) => {
        const updatedValues = [...variableValues];
        updatedValues[index] = option;
        setVariableValues(updatedValues);
      };

    const getAvailableOptions = (index) => {
        return messageArray.filter((option) => {
          return variableValues.every((value, i) => i === index || value?.value !== option.value);
        });
    };
   
    const generateInputFields = () => {
        const numInputFields = noOfVariables ? parseInt(noOfVariables, 10) : 0;

        if (numInputFields > listOfArray.length) {
            return [];
        }

        const rows = [];
        for (let i = 0; i < numInputFields; i += 2) {
          const firstIndex = i;
          const secondIndex = i + 1;
    
          const firstField = (
            <Col md={6} key={firstIndex}>
              <Form.Group className="mb-3">
                <Form.Label>{`Variable ${firstIndex + 1}`}</Form.Label>
                <Select
                  isMulti={false} isSearchable={true}
                  name={variable}
                  value={variableValues[firstIndex] || null}
                  options={getAvailableOptions(firstIndex)}
                  onChange={(option) => handleVariableChange(option, firstIndex)}
                />
                 {variableErr[firstIndex] && <p className="errMsg">Please select Variable {firstIndex + 1}</p>}
              </Form.Group>
            </Col>
          );
    
          const secondField =
            secondIndex < numInputFields ? (
              <Col md={6} key={secondIndex}>
                <Form.Group className="mb-3">
                  <Form.Label>{`Variable ${secondIndex + 1}`}</Form.Label>
                  <Select
                    isMulti={false}
                    isSearchable={true}
                    name={variable}
                    value={variableValues[secondIndex] || null}
                    options={getAvailableOptions(secondIndex)}
                    onChange={(option) => handleVariableChange(option, secondIndex)}
                  />
                  {variableErr[secondIndex] && <p className="errMsg">Please select Variable {secondIndex + 1}</p>}
                </Form.Group>
              </Col>
            ) : null;
    
          rows.push(
            <Row key={i}>
              {firstField}
              {secondField}
            </Row>
          );
        }
    
        return rows;
    };

    const handleNoOfVariableChange = (e) => {
        const value = e.target.value;
        if (!value.match(/[A-Za-z+@#$&%!~=*./\s/g/\-/?<>,"/]/) && listOfArray.length >= value) {
            setVariableValues([])
            setNoOfVariables(value);
            value.length > 0 ? 
            setNoOfVariablesErr(false) : 
            setNoOfVariablesErr(true);
        } else if(!value.match(/[A-Za-z+@#$&%!~=*./\s/g/\-/?<>,"/]/)){
            setNoOfVariables(value);
            toast.error("Please Enter less than message words count");
        }
    };

    return (
        <Modal size='lg' show={show} backdrop='static' keyboard={false} centered onHide={closeAddModal}>
            <Modal.Header closeButton>
                <Modal.Title> <h4 className='mb-0'><Icon.EnvelopePlus size={20} className='me-2' />Add Template</h4> </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Template Title<span className='errMsg'>*</span></Form.Label>
                            <Form.Control
                                placeholder='Enter template title'
                                name='templateTitle'
                                value={templateTitle}
                                onChange={(e) => {
                                    if (/^[a-z_]+$/.test(e.target.value) && e.target.value.length <= 512 &&
                                        e.target.value.trim()) {
                                        setTemplateTitle(e.target.value);
                                    } else if (e.target.value.length === 0) {
                                        setTemplateTitle(e.target.value);
                                    }
                                    e.target.value.length > 0 ?
                                        setTemplateTitleErr(false) :
                                        setTemplateTitleErr(true);
                                }} />
                            {templateTitleErr ? <p className='errMsg'>Please enter valid template title</p> : ''}
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Department Name<span className='errMsg'>*</span></Form.Label>
                            <Select
                                isMulti={false}
                                isSearchable={true}
                                name='departmentName'
                                value={departmentName}
                                options={commonData?.departments}
                                onChange={(option) => {
                                    handleDepartment(option);
                                }}
                            />
                            {departmentNameErr ? <p className='errMsg'>Please enter valid department name</p> : ''}
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Template Category<span className='errMsg'>*</span></Form.Label>
                            <Select
                                isMulti={false}
                                isSearchable={true}
                                name='category'
                                value={category}
                                options={categoryList}
                                onChange={(option) => {
                                    handleCategoryList(option);
                                    setCategoryErr(false);
                                }}
                            />
                            {categoryErr ? <p className='errMsg'>Please enter category</p> : ''}
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Template Language<span className='errMsg'>*</span></Form.Label>
                            <Select
                                isMulti={false}
                                isSearchable={true}
                                name='language'
                                value={language}
                                options={langList}
                                onChange={(option) => {
                                    handleLanguageList(option);
                                    setLanguageErr(false);
                                }}
                            />
                            {languageErr ? <p className='errMsg'>Please select template language</p> : ''}
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Created Date<span className='errMsg'>*</span></Form.Label>
                            <Form.Control
                                type='date'
                                placeholder='Select created date'
                                name='createdDate'
                                value={createdDate}
                                onChange={(e) => {
                                    setCreatedDate(e.target.value)
                                    e.target.value.length > 0 ?
                                        setCreatedDateErr(false) :
                                        setCreatedDateErr(true);
                                }} />
                            {createdDateErr ? <p className='errMsg'>Please enter created date</p> : ''}
                        </Form.Group>
                    </Col>

                    {/* <Col md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Channel End Date</Form.Label>
                            <Form.Control
                                type='date'
                                placeholder='Enter created by name'
                                name='channelEndDate'
                                value={channelEndDate}
                                onChange={(e) => {
                                    setChannelEndDate(e.target.value)
                                    e.target.value.length > 0 ?
                                        setChannelEndDateErr(false) :
                                        setChannelEndDateErr(true);
                                }} />
                            {channelEndDateErr ? <p className='errMsg'>Please enter channel end date</p> : ''}
                        </Form.Group>
                    </Col> */}
                </Row>

                <Row>
                    <Col md={12}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Template Message<span className='errMsg'>*</span></Form.Label>
                            <Form.Control
                                as="textarea"
                                placeholder='Enter message'
                                name='message'
                                value={message}
                                onChange={(e) => {
                                    handleMessageOnChange(e)
                                    e.target.value.length > 0 ?
                                        setMessageErr(false) :
                                        setMessageErr(true);
                                }} />
                            {messageErr ? <p className='errMsg'>Please enter valid message</p> : ''}
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>No of variables<span className='errMsg'>*</span></Form.Label>
                            <Form.Control
                                placeholder='Enter No. of variables'
                                name='noOfVariables'
                                value={noOfVariables}
                                onChange={handleNoOfVariableChange} />
                            {noOfVariablesErr ? <p className='errMsg'>Please enter no of variables</p> : ''}
                        </Form.Group>
                    </Col>

                    { generateInputFields()}
                </Row>
            </Modal.Body>

            <Modal.Footer>
                <Button className='primaryBtn' onClick={addTemplate} disabled={loader}>
                    {loader ? <Spinner size='sm' /> : <Icon.EnvelopePlus className='me-2' />}Add Template</Button>
            </Modal.Footer>
        </Modal>
    )
}
