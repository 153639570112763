import { combineReducers, configureStore } from '@reduxjs/toolkit';
import LoginReducer from '../Features/Auth/LoginSlice';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import sidebarReducer from '../Features/Common/ActiveKeySlice';
import activeChatReducer from '../Features/Messenger/ChatSlice';
import commonReducer from '../Features/Admin/commonSlice';

const reducers = combineReducers({
  loginData: LoginReducer,
  activeData : sidebarReducer,
  activeChatReducer,
  commonData : commonReducer,
})

const persistConfig = {
  key: 'root',
  storage
}

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);
