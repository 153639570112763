import React, { useEffect, useState } from 'react';
import {Form, InputGroup, Spinner } from 'react-bootstrap';
import Avatar from '../../Assets/Avatar.jpg';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveChat} from './ChatSlice';
import { styled } from 'styled-components';
import * as Icon from 'react-bootstrap-icons';

/* ---------------- Styling ---------------------- */
const ContHeader = styled.div`
    background: #ededed;
    padding:10px;
    height:50px;
    border-right:1px solid #d7d7d7;
    display:flex;
    align-items:center;
    justify-content:space-between;
`;

const ContHeaderImage = styled('img')`
    height:40px;
    width:40px;
    border-radius:50%;
`;

const ContSearchDiv = styled('div')`
    padding:10px;
    background:#ffffff;
    height:8vh;
    border-bottom:2px solid #ededed;
`;


const ContactList = () => {

    const userState = useSelector(state => state?.loginData?.userData?.data);
    const activeChatData = useSelector(state => state?.activeChatReducer?.activeChatData);
    const dispatch =  useDispatch();

        // States for Users ------
        const [users, setUsers] = useState([]);

        const [loader, setLoader] = useState(false);
        const [contSearch, setContSearch] = useState('');
   
    // States for Pagination ----------
        const [pageNo, setPageNo] = useState(1);
        const [docPerPage, setDocPerPage] = useState(20);

        useEffect(()=>{
            getAllUsers();
        },[])
       

    // const {socket, setActiveUsers} = useContext(AccountContext);

    //     useEffect(()=>{
    //         console.log("socket ====>", socket);
    //         console.log("adduser, userData", account);
    //         socket?.current?.emit("addUser", account);
    //         console.log("Account Id", account?._id);
    //         socket?.current?.on("getUsers", users =>{
    //             console.log("users given by socket", users);
    //             setActiveUsers(users);
    //         })
    //     },[account])

 
      

    
    const getAllUsers = async()=>{
        console.log("InSIDE API");
        setLoader(true);
        try{
            const result = await fetch(process.env.REACT_APP_BASE_URL + `/conversation/departmentHeadConversations/${userState?.userId}`,
            {
                method:'POST',
                headers:{'Content-Type':'application/json'},
                body:JSON.stringify({ documentsPerPage: docPerPage, page:pageNo})
            })
            
            const response = result.json();
            setUsers(response?.data?.consumers);
            setPageNo(response?.data?.noOfPages);
            setLoader(false);
        }catch(Err){
            console.log("Error while getting users", Err);
            setLoader(false);
        }
    }

    const setConversation= async(itm)=>{
         dispatch(setActiveChat(itm)); 
    }



  return (
    <div>
          <ContHeader>
                <ContHeaderImage src={Avatar} alt="Profile"/>
                <Icon.ThreeDotsVertical className='me-2' size={18}/>
         </ContHeader>

         {/* <ContSearchDiv>
         <InputGroup>
                  <InputGroup.Text><Icon.Search size={20}/></InputGroup.Text>
                        <Form.Control value={contSearch} placeholder="search here ......."/>
                  </InputGroup>
         </ContSearchDiv> */}

    
    
    <div className='ContactList'>
      {
        loader ? <center><Spinner variant='info' className='mt-2'/></center> : 
        users !== null && users !== undefined && users?.length > 0 ? users?.map((itm, index) => {
            const { consumerName, consumerContactNo} = itm
            return(
                <div className={activeChatData?.consumerName === consumerName ? ' contactCard contactCardActive mb-2' : 'contactCard mb-2'} onClick={()=>setConversation(itm)}>
                <img src={Avatar} width={40} height={40} alt='Profile' style={{borderRadius:'50%'}}/>
                <div className='w-100 ms-2'>
                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                        <h6>{consumerName}</h6>
                        {/* <p className='lastMsgDate'>{consumerContactNo}</p> */}
                    </div>
                   <p className='lastMsg'>{consumerContactNo}</p>
                </div>
        </div>
            )
        }) :<p className='text-center text-danger my-4'>No Users Found</p>
      }
        </div>    
    </div>
  )
}

export default ContactList