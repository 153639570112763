import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import Pagination from '../Common/Pagination';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { setMultiMsg } from '../Common/ActiveKeySlice';
import TableSkeleton from '../Common/TableSkeleton';

const SendMultipleMsg = ({ data }) => {

    const dispatch = useDispatch();

    const userState = useSelector(state => state?.loginData?.userData?.data);
    const [consumerList, setconsumerList] = useState('');
    const [loader, setLoader] = useState(false);
    const [btnLoader, setBtnLoader] = useState(false);

    // States for Pagination --------------------------
    const [pageNo, setPageNo] = useState(1);
    const [docPerPage, setDocPerPage] = useState(10);
    const [noOfPages, setNoOfPages] = useState();

    useEffect(() => {
        setvaribalesObjects()
    }, [])

    useEffect(() => {
        getAllConsumers();
    }, [pageNo, docPerPage])



    const getAllConsumers = async () => {
        setLoader(true);
        await fetch(process.env.REACT_APP_BASE_URL + `/consumer?searchField=&pageNo=${1}&docPerPage=${20}`, {
            method: 'GET'
        }).then((res) => res.json()).then((response) => {
            console.log("response",response );
            setconsumerList(response?.data?.consumers);
            let arr = []
            for(let i=0 ; i < response?.data?.consumers.length ; i++){
                if(selectedItems.filter(row => row.consumerId === response?.data?.consumers[i].consumerId).length > 0){
                    arr[i] = true
                }else{
                    arr[i] = false
                }
            }
            setSelectedCheck(arr)
            setNoOfPages(response?.data?.noOfPages);
            setLoader(false);
        }).catch((err) => {
            console.log("Error while getting users", err);
            setLoader(false);
        })
    }

    const setvaribalesObjects = () => {
        let arr = {}
        for (let i = 0; i < data?.variable?.length; i++) {
            arr = { ...arr, [`variable${i + 1}`]: '' }
        }
        setVariables(arr);

        let boolArray = []
        for (let i = 0; i < data?.variable?.length; i++) {
            boolArray.push(false)
        }
        setVariablecheck(boolArray)
    }

    // function for pagination
    const handlePageChange = (pageNumber) => {
        setPageNo(pageNumber);
    };

    const handleDocsPerPage = (docsPageProp) => {
        setPageNo(1)
        setDocPerPage(docsPageProp);
    }


    const fieldsOptions = [
        {
            "value": "firstName",
            "label": "First Name"
        },
        {
            "value": "lastName",
            "label": "Last Name"
        },
        {
            "value": "whatsappNo",
            "label": "WhatsApp Number"
        },
    ]

    console.log("consumer list",consumerList);

    /* ----------------------- Getting Selected Consumers ----------------------- */

    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedCheck, setSelectedCheck] = useState([]);

    // Handle checkbox change -----------------------------------
    const handleCheckboxChange = (item,index) => {
        // console.log("1", selectedItems.filter(row => row.consumerId === item.consumerId).length > 0, item)
        if (selectedItems.filter(row => row.consumerId === item.consumerId).length > 0) {
            let arr = selectedItems
            let getvalue = arr.findIndex((event => event.consumerId === item.consumerId))
            arr.splice(getvalue, 1);

            let checkArray = [...selectedCheck]
            checkArray[index] = false
            setSelectedCheck(checkArray)
            setSelectedItems(arr)
        } else {
            let arr = selectedItems
            arr.push(item)
            // console.log("selectedOptions", arr);
            setSelectedItems(arr)
            let checkArray = [...selectedCheck]
            checkArray[index] = true
            setSelectedCheck(checkArray)
        }
    };


    /*-------------- Filtering Varibales Options ---------------*/
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [variables, setVariables] = useState();
    const [variablecheck, setVariablecheck] = useState([])

    const availableOptions = fieldsOptions.filter(option => !selectedOptions.includes(option.value));

    const handleSelectChange = (selectedOption, index) => {
        // setSelectedOptions([...selectedOptions, selectedOption?.value]);
        setVariables({ ...variables, [`variable${index}`]: selectedOption?.value });
    };

    const handleTypeChange = (value, index) => {
        setVariables({ ...variables, [`variable${index}`]: value })
    }


    const filteredOptions = availableOptions.map(option => ({ value: option.value, label: option.label }));

    const sendBulkMsg = async () => {
        setBtnLoader(true);
        if (selectedItems?.length === 0) {
            toast.error('Please select consumers');
            setBtnLoader(false);
        }
        else {
            const consumers = selectedItems?.length > 0 &&
                selectedItems?.map((itm) => {
                    return ({
                        consumerId: itm?.consumerId,
                        consumerFName: itm?.firstName,
                        consumerLName:itm?.lastName,
                        consumerContactNo: itm?.whatsappNo,
                        parameters: Object.keys(variables).map((i, index) => {
                            console.log("first", variables[`variable${index + 1}`]);
                            console.log("Data", data);
                            console.log("two", variables[`variable${index + 1}`]);
                            return variables[`variable${index + 1}`] === '' ? data.variable[index] : 
                                itm[variables[`variable${index + 1}`]] !== undefined ? 
                                  fieldsOptions.filter( (itm) =>variables[`variable${index + 1}`]===itm.value)[0].value :  variables[`variable${index + 1}`]
                        })
                    })
                })

            await fetch(process.env.REACT_APP_BASE_URL + '/conversation/sendBulkMessage', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    templateId: data?.templateId,
                    templateInitateById: userState?.userId,
                    templateInitateByName: userState?.name,
                    templateInitateByContactNo: userState?.contactNo,
                    templateInitateTime: new Date(),
                    consumers
                })
            }).then(res => res.json()).then((response) => {
                if (response.message === "Message sent Successfully") {
                    toast.success('Message sent Successfully');
                    setBtnLoader(false);
                }
                else if (response.message === "No credits available for this department") {
                    toast.error('No credits available for this department');
                    setBtnLoader(false);
                }
                else if (response.message === "Not enough credits available for this department") {
                    toast.error('Not enough credits available for this department');
                    setBtnLoader(false);
                }
            }).catch((Err) => {
                console.log("Err while sending bulk msgs", Err);
                setBtnLoader(false);
            })
        }

    }

    const changevariable = (event, index) => {
        let arr = [...variablecheck]
        if (event.target.checked) {
            arr[index] = true
            // console.log(arr);
            setVariablecheck(arr);
        } else {
            arr[index] = false
            setVariablecheck(arr)
        }
    }


    return (
        <div className='SendMultipleMsg mt-4 mb-4' id="sendMultiMsg" style={{ scrollMarginBottom: '100vh' }}>
            <ToastContainer />
            <Icon.XLg size={22} className='cancel' onClick={() => dispatch(setMultiMsg(false))} />
            <Container>
                <Row className='d-flex'>
                    <h5 className='text-center'>Send Multiple Messages</h5>
                    <Row className='justify-content-center my-2'>
                        <h6 className='text-center'>
                            <Icon.ArrowDownCircle className='me-1' size={15} /> Manage Variables
                        </h6>
                        {
                            data?.variable?.map((itm, index) => {
                                return (
                                    <Col md={3} key={index} className='mb-3 mb-md-0' style={{ zIndex: '1000' }}>

                                        <Form>
                                            <Form.Label>Variable {index + 1}</Form.Label>
                                            {variablecheck[index] ? <Select options={filteredOptions} isMulti={false} isSearchable={false} isClearable={true}
                                                onChange={(option) => handleSelectChange(option, index + 1)} placeholder={<div style={{ color: '#000000' }} >
                                                    {itm}</div>} /> :
                                                <Form.Control placeholder={`Enter variable ${index + 1}`} onChange={(e) => handleTypeChange(e.target.value, index + 1)} />
                                            }
                                            <Form.Check className='mt-1' label="Use Variable" name="Group1" type="checkbox" id={`inline-radio-1`} value={variablecheck[index]} onClick={(event) => changevariable(event, index)} />
                                        </Form>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                    <div className="outer-wrapper">
                        <div className="table-wrapper" style={{ maxHeight: '55vh' }} >
                            <table>
                                <thead>
                                    <th>Select</th>
                                    <th>Sr No</th>
                                    <th>Name</th>
                                    <th>Whatsapp number</th>
                                    <th>Gender</th>
                                    <th>Date of Birth</th>
                                </thead>

                                <tbody>
                                    {
                                        loader ?
                                            <TableSkeleton rows={10} cols={6} />

                                            :

                                            consumerList !== null && consumerList !== undefined && consumerList.length > 0 ? consumerList.map((itm, index) => {

                                                const { consumerId, firstName, lastName, whatsappNo, gender, dob } = itm

                                                return (
                                                    <>
                                                        <tr key={consumerId}>
                                                            <td>
                                                            <Form.Check checked={selectedCheck[index]} onChange={() => handleCheckboxChange(itm,index)} /></td>
                                                            <td>{pageNo !== 1 ? (<> {index + 1 + docPerPage * (pageNo - 1)}</>) : <>{index + 1}</>}</td>
                                                            <td>{`${firstName} ${lastName}`}</td>
                                                            <td>{whatsappNo}</td>
                                                            <td>{gender}</td>
                                                            <td>{new Date(dob).toLocaleDateString('en-GB')}</td>
                                                        </tr>

                                                    </>
                                                )
                                            })
                                                : 'No data Found'
                                    }
                                </tbody>

                            </table>
                        </div>


                    </div>

                    <Row className='justifyc-content-center align-items-center'>
                        <Col md={6} className='d-flex justify-content-center justify-content-md-end align-items-center'>
                            {btnLoader ? <center><Spinner variant='primary' /></center> :
                                <Button className='mt-3 primaryBtn' onClick={() => { sendBulkMsg() }} >
                                    <Icon.Send className='me-2' />Send
                                </Button>
                            }
                        </Col>
                        <Col md={6} xs={12} style={{ scrollMarginBottom: '200px' }}>
                            <Pagination currentPage={pageNo} totalPages={noOfPages}
                                onPageChange={handlePageChange} onDocsPerPage={handleDocsPerPage} docPerPage={docPerPage}
                            />
                        </Col>
                    </Row>

                </Row>
            </Container>


            {/* <Button className='send primaryBtn' onClick={() => getSelectedObjects()}>
                         <Icon.Send className='me-2'/>Send
            </Button>  */}


        </div>
    )


}

export default SendMultipleMsg;